import React from 'react';
import {AiOutlineSearch} from 'react-icons/ai';

const SerchbarHour = ({ posts, setSerchRecords, filter}) => {
    const handleSubmit = (e) => e.preventDefault()
    const handleSerchChange = (e) => {
        if (!e.target.value) return setSerchRecords(posts.filter(post => post.date.includes(filter)))

        const resultArray = posts.filter(post => (post.discr.toLowerCase()).includes(e.target.value.toLowerCase()) && post.date.includes(filter))
        setSerchRecords(resultArray)
    }

    return (
        <form onSubmit={handleSubmit}>
            <input className="input w-full max-w-xs input-bordered" placeholder="Search" type="text" id="serch"
                   onChange={handleSerchChange}/>
        </form>
    )
};

export default SerchbarHour;
