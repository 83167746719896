import React, {useEffect, useState} from 'react';
import {VictoryChart,VictoryBar,} from 'victory';
import PocketBase from "pocketbase";
import { Bar, BarChart,BarSeries } from 'reaviz';
import {da} from "date-fns/locale";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const TimeWorkChart = ({records}) => {

    var data = [];
    const date = new Date();
    const weekdays = ["Mon", "Din", "Mit", "Don", "Fri", "Sam","Son"]; // Korrigierte Reihenfolge der Wochentage
    const [loading, setLoading] = useState(true);
    const currentDayOfWeek = date.getDay();
    const daysToAdd = currentDayOfWeek > 0 ? -(currentDayOfWeek - 1) : 0;

    const mondate = new Date(date);
    mondate.setDate(date.getDate() + daysToAdd);



    const calc = (records) =>{
        for (var i = 0; i < 5; i++) {
            const ndate = new Date(mondate.getFullYear(), mondate.getMonth(), mondate.getDate() + i);
            const filtredRecords = records.filter(post => post.date.includes(`${ndate.getFullYear()}-${((ndate.getDate().toString()).length != 1) ? "":"0"}${ndate.getMonth() + 1}-${((ndate.getDate().toString()).length != 1) ? "":"0"}${ndate.getDate() + 1}`))
            var calctime = 0;
            for (var j in filtredRecords) {
                calctime += filtredRecords[j].time
            }
            data[i] = {key: weekdays[i], data: calctime}
        }
    }
    calc(records)

    useEffect(() => {
        calc(records)
    }, []);
    return (
        <>
            <BarChart data={data} series={<BarSeries colorScheme={"#ff6633"} padding={0.1} bar={<Bar />} />} />        </>
    );
}

export default TimeWorkChart;
