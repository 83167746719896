import React, {useState} from 'react';
import {del, update} from "../../api/crud";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import PocketBase from "pocketbase";
import {Link} from "react-router-dom";
import HourEdit from "./hourEdit";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');


const Hour = ({serchRecords, setSearchRecords, setUnfiltredRecords, edit, setMultiEdit, editData, setEditdata, editVis, setEditVis, clientList, invList, assignmentList, persList}) => {

    const [multiSelEdit, setMultiSelEdit] = useState([]);
    const [client, setClient] = useState();
    const [assignment, setAssignment] = useState();
    const filtredAssignments = Array.from(assignmentList).filter(post => post[1].includes(client))

    const handleMultiEdit = async () => {
        for (var i in multiSelEdit) {
            edit(multiSelEdit[i])
            setEditVis(false)
            let obj = {
                id: multiSelEdit[i],
                client: client,
                assignment: assignment,
            }
            update("time", obj)
        }
        window.location.reload()
    }

    const handleDelete = async (data) => {
        del("time", data.id, data.old_time_id)
        setSearchRecords(prevRecords => [...prevRecords].filter(post => post.id !== data.id));
        setUnfiltredRecords(prevRecords => [...prevRecords].filter(post => post.id !== data.id));
    }

    const handleSelect = (e,id) => {
        e.target.checked ? multiSelEdit.push(id) : multiSelEdit.splice(multiSelEdit.indexOf(id),1);
        (multiSelEdit.length <= 0) ? setMultiEdit(false):setMultiEdit(true);
        console.log(multiSelEdit)
    }
    const updCheckboxes = async (id,box,boxVal) =>{
        let obj ={
            [box]:boxVal
        }
        await pb.collection('time').update(id,obj)
        window.location.reload()
    }
    const dater = (data) =>{
        const date = new Date(data)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${((date.getDate().toString()).length != 1) ? "":"0"}${date.getDate()}`
    }

    return (
        <>
        {serchRecords.map((data, index) => (
            <div key={index}>
            {editVis && editData.id === data.id ? <HourEdit editData={editData} setEditdata={setEditdata} editVis={editVis} setEditVis={setEditVis} assignmentList={assignmentList} clientList={clientList} invList={invList} persList={persList} setUnfiltredRecords={setUnfiltredRecords} setSearchRecords={setSearchRecords}/>:
             <div className="grid grid-flow-col grid-cols-10 gap-5 h-30 mb-6 border border-primary bg-base-100 text-primary rounded-2xl items-center shadow-2xl">
                    <div className="flex justify-center px-5  col-start-1"><input type="checkbox" className="checkbox checkbox-MD focus:outline-none focus:shadow-outline checked:checkbox-accent" onClick={e => handleSelect(e,data.id)} /*handleClick={handleClick} isChecked={isCheck.includes(index)}*//></div>
                    <div className="w-5/6"><p className="">{dater(data.date)}</p></div>
                    <div className="overflow-hidden hover:overflow-auto">
                        <Link to={"/client/" + data.expand.client.id}>{data.expand.client.client_name}</Link>
                    </div>
                    <div className="overflow-hidden hover:overflow-auto "><Link
                        to={"/assignment/" + data.expand.assignment.id}>{data.expand.assignment.assignments_name}</Link>
                    </div>
                    <div className="text-md font-bold col-span-2 my-4">{data.discr}</div>
                    <div className="flex justify-center">{data.expand.person.name}</div>
                    <div className="flex justify-center">{data.expand.invoice.invoice}</div>
                    <div className="flex justify-center z-0">
                        <div className="indicator">
                            {data.paid && (<button className="indicator-item badge badge-success" onClick={() => updCheckboxes(data.id, "paid", !data.paid)}></button>)}
                            {!data.paid && (<button className="indicator-item badge badge-error" onClick={() => updCheckboxes(data.id, "paid", !data.paid)}></button>)}
                            <div className="grid h-8 w-8 place-items-center">{data.time.toFixed(2)}</div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <button onClick={() => handleDelete(data)} className="hover:text-maxrange text-3xl mx-3"><AiOutlineDelete/></button>
                        <button onClick={() => edit(data.id)} className="hover:text-maxrange text-3xl mx-3"><AiOutlineEdit/></button>
                    </div>
                </div>}
            </div>
            ))}
            <dialog id="multiEditModal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Stunden Verschieben</h3>
                    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5" id="CLietEdit" onChange={e=> setClient(e.target.value)} ><option hidden value="">Kunde</option>{Array.from(clientList).map(([value, key])=> (<option key={key} value={value}>{key}</option>))}</select>
                    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5" id="AssignmentEdit" onChange={e=> setAssignment(e.target.value)} ><option hidden value="">Auftrag</option>{Array.from(filtredAssignments).map(([value, key])=> (<option key={key} value={value}>{key[0]}</option>))}</select>
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn mx-2" onClick={handleMultiEdit}>Verschieben</button>
                            <button className="btn">Abbruch</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    )
};

export default Hour;
