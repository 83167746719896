import React, {useState} from 'react';
import {del, update} from "../../api/crud";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import PocketBase from "pocketbase";
import {Link} from "react-router-dom";

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    HeaderCell,
    Cell,
} from "@table-library/react-table-library/table";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');


const Hour = ({serchRecords, edit, setMultiEdit, editData, editVis, setEditVis, clientList, assignmentList}) => {

    const [multiSelEdit, setMultiSelEdit] = useState([]);
    const [client, setClient] = useState();
    const [assignment, setAssignment] = useState();
    const filtredAssignments = Array.from(assignmentList).filter(post => post[1].includes(client))

    const handleMultiEdit = async () => {
        for (var i in multiSelEdit) {
            edit(multiSelEdit[i])
            setEditVis(false)
            let obj = {
                id: multiSelEdit[i],
                client: client,
                assignment: assignment,
            }
            update("time", obj)
        }
        window.location.reload()
    }

    const handleUpdate = (e, id, name) => {
        console.log("Update", e, id, name)
    }


    const handleSelect = (e,id) => {
        e.target.checked ? multiSelEdit.push(id) : multiSelEdit.splice(multiSelEdit.indexOf(id),1);
        (multiSelEdit.length <= 0) ? setMultiEdit(false):setMultiEdit(true);
        console.log(multiSelEdit)
    }
    const updCheckboxes = async (id,box,boxVal) =>{
        let obj ={
            [box]:boxVal
        }
        await pb.collection('time').update(id,obj)
        window.location.reload()
    }
    const dater = (data) =>{
        const date = new Date(data)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${((date.getDate().toString()).length != 1) ? "":"0"}${date.getDate()}`
    }

    const data = Object.assign({}, serchRecords)
    console.log(serchRecords)

    return (
        <Table data={data}>
                    {(tableList) => (
                        <>
                            <Header>
                                <HeaderRow>
                                    <HeaderCell>Task</HeaderCell>
                                </HeaderRow>
                            </Header>
        
                            <Body>
                                {tableList.map((item) => (
                                    <Row key={item.id} item={item}>
                                        <Cell>
                                            <input
                                                type="text"
                                                style={{
                                                    width: "100%",
                                                    border: "none",
                                                    fontSize: "1rem",
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                                value={item.discr}
                                                onChange={(event) =>
                                                    handleUpdate(event.target.value, item.id, "discr")
                                                }
                                            />
                                        </Cell>
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
    )
};

export default Hour;
