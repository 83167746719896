import React, { useEffect, useState, } from 'react';
import PocketBase from "pocketbase";
import "react-datepicker/dist/react-datepicker.css";
import Assignment from "./assignment";
import AssignmentEdit from "./assignmentEdit";
import AssignmentAdd from "./assigmentAdd";
import {add} from "../../api/crud";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const AssignmentList = ({records,unfRec, clientList, hassignment_id, setSearchRecords, setUnfiltredRecords}) => {
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);
    const [editVis, setEditVis] = useState(false);
    const [editData, setEditdata] = useState({});
    const [prevID, setPrevID] = useState(0);

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleChange = (val,e) => {
        let obj = {}
        obj = {
            [val]: e.target.value
        };
        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    const edit = async (editId) => {
        setEditVis(!editVis)
        const gettetOne = await pb.collection('assignments').getOne(editId);
        setEditdata(gettetOne)
        console.log(editData)
    };

    const updCheckboxes = async (id,box,boxVal) =>{
        const assignment = await pb.collection('assignments').getOne(id);

        let obj = {};
        if (box === "completed"){
            if (boxVal) {
                obj = {
                    [box]:boxVal,
                    e_date: new Date()
                }
            } else {
                obj = {
                    [box]:boxVal,
                    e_date: null
                }
            }
        } else if (box === "completedCheck") {
            if (assignment.recurring === true){
                setEditdata(assignment)
                setPrevID(assignment.id)
                setEditdata(editData => ({...editData,...{old_assign_id: hassignment_id + 1}}))
                setEditdata(editData => ({...editData,...{a_date: new Date()}}))
                setEditdata(editData => ({...editData,...{id: null}}))
                document.getElementById('recreateRecurring').showModal()
                obj = {
                    ['completed']:boxVal,
                    e_date: new Date()
                }
            } else {
                obj = {
                    ['completed']:boxVal,
                    e_date: new Date()
                }
            }
        } else {
            obj = {
                [box]:boxVal
            }
        }
        await pb.collection('assignments').update(id,obj)
        box === "completedCheck" && assignment.recurring === true ? console.log("reload nach schliesen des Modals") : window.location.reload()
    }

    return (
        <div className=" w-full relative m-4 ">
            <Assignment
                records={records}
                updCheckboxes={updCheckboxes}
                edit={edit}
                setUnfiltredRecords={setUnfiltredRecords}
                setSearchRecords={setSearchRecords}
                clientList={clientList}
                editVis={editVis}
                setEditVis={setEditVis}
                editdataEdit={editData}
                setEditdata={setEditdata}
            />
            <AssignmentAdd
                records={unfRec}
                clientList={clientList}
                hassignment_id={hassignment_id}
                setSearchRecords={setSearchRecords}
                setUnfiltredRecords={setUnfiltredRecords}
            />
            <dialog id="recreateRecurring" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg pb-5">Auftrag Wiederkehrend</h3>
                    <input type="text" placeholder="Neuer Auftrags Name" value={editData.assignments_name}
                           className="input input-bordered w-full max-w-xl col-span-1 bg-white"
                           onChange={e => handleChange("assignments_name", e)}/>
                    <div className="modal-action">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn mx-2" onClick={() => add("assignments", editData)}>Neuen auftrag
                                Anlegen
                            </button>
                            <button className="btn mx-2"
                                    onClick={() => updCheckboxes(prevID, "completed", !editData.completed)}>Trotzdem
                                {editData.completed ? " Reaktiviren" : " Abschliesen"}
                            </button>
                            <button className="btn mx-2 my-2">Abbruch</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>
    );
}

export default AssignmentList;
