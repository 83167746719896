import React, {useEffect, useState, forwardRef} from 'react';
import { useParams } from 'react-router-dom';
import {del,add, getOne, getRecords} from "../../api/crud";
import SerchbarHour from "../hours/serchbarHour";
import PocketBase from "pocketbase";
import {AiOutlineClose, AiOutlineDelete, AiOutlineEdit, AiOutlinePlus} from "react-icons/ai";
import Hourlist from "../hours/hourlist";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const PrintAssignment = forwardRef(({records, assignment}, ref) => {
    //const {assignmentId} = useParams()
    console.log(records)

    var sumGes = 0
    var sumFakt = 0
    for (var i = 0; i < records.length; i++){
        sumGes += records[i].time
        if (records[i].paid){
            sumFakt += records[i].time
        }
    }
    const dater = (data) =>{
        const date = new Date(data)
        return `${date.getFullYear()}-${date.getMonth() + 1}-${((date.getDate().toString()).length != 1) ? "":"0"}${date.getDate() -1 }`
    }

    return (
        <div aria-disabled="!isValide" className="App w-full bg-plat" ref={ref}>
            <div className="mx-5 mt-5">
                <img src="../../ma-xlogo.jpeg" width="100px"></img>
            </div>
            <div className="flex flex-col h-screen w-full">
                <div className="flex flex-col border-black border-b bg-white ">
                    <div className="flex justify-between align-middle mx-5 h-20">
                        <div className="relative self-center border-r max-w-xs">
                            <h1 className="text-1xl font-medium mr-5 text-black">{assignment.assignments_name}</h1>
                        </div>
                        <div className="flex justify-center self-center b px-3"><label
                            className="absolute text-xs -translate-y-5 mx-3">Angelegt</label>{dater(assignment.a_date)}</div>
                        <div className="flex justify-center self-center b px-3"><label
                            className="absolute text-xs -translate-y-5 mx-3">Abgeschlossen</label>{assignment.e_Date ? records.e_Date :
                            <p className="text-success underline">Aktiv</p>}</div>
                        <div className="flex justify-center self-center px-3"><label
                            className="absolute text-xs -translate-y-4 mx-3">Stunden</label><p>Fakt: <u
                            className="text-success">{sumFakt.toFixed(2)}</u><br/>Ges: {sumGes.toFixed(2)}</p></div>
                        <div className="flex justify-center self-center b px-3"><label
                            className="absolute text-xs -translate-y-5 mx-3">Budget</label>{assignment.budget !== 0 ? <p className="text-success">{assignment.budget + "€"}</p> : <p>{assignment.budget + "€"}</p> }
                        </div>
                        <div className="flex justify-center self-center b px-10"><label
                            className="absolute text-xs -translate-y-5 mx-3">Stundenlohn</label>{assignment.budget === 0 ? <p className="text-success">{assignment.hourRate + "€"}</p> : <p>{assignment.hourRate + "€"}</p> }
                        </div>
                    </div>
                </div>
                <div className="flex justify-center overflow-auto">
                    <table className="table w-full mx-10">
                        <thead>
                        <tr className="border-b">
                            <th className="">Datum</th>
                            <th className="">Tätigkeit</th>
                            <th className="">Person</th>
                            <th className="">Abrechnung</th>
                            <th className="">Berechnete Stunden</th>
                        </tr>
                        </thead>
                        {records.map((data, index) => (
                            <tr key={index}>
                                <td className="">{dater(data.date)}</td>
                                <td className="">{data.discr}</td>
                                <td className="">{data.expand.person.username}</td>
                                <td className="">{data.expand.invoice.invoice}</td>
                                <td className="">{data.time}</td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    );
});

export default PrintAssignment;
