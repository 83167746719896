import React, {useEffect, useState} from "react";
import './App.css';
import LoginScreen from "./components/loginScreen";
import PocketBase from "pocketbase";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Dashboard from "./pages/Dashboard";
import Hours from "./pages/Hours";
import Clients from "./pages/Clients";
import Assignments from "./pages/Assignments";
import Statistcs from "./pages/Statistcs";
import Assignment from "./pages/Assignment";
import ClientsAssignments from "./pages/ClientsAssignments";
import Testingcomponent from "./components/testingcomponent";
import { Toaster } from "sonner";


const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

function App() {
    const [isValide, setIsValide] = useState(pb.authStore.isValid)

    useEffect(() =>{
        setIsValide(pb.authStore.isValid)
    },)

  return (
      <div>
      <Toaster richColors />
      {isValide == false && (
              <LoginScreen></LoginScreen>
          )}
      {isValide == true && (
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Layout />}>
                      <Route index element={<Dashboard />} />
                      <Route path="hours" element={<Hours />} />
                      <Route path="clients" element={<Clients />} />
                      <Route path="assignments" element={<Assignments />} />
                      <Route path="stats" element={<Statistcs />} />
                      <Route path="assignment/:assignmentId" element={<Assignment />} />
                      <Route path="testing" element={<Testingcomponent />} />
                      <Route path="client/:clientId" element={<ClientsAssignments />} />
                  </Route>
              </Routes>
          </BrowserRouter>
      )}
      </div>
  );
}

export default App;
