import React from 'react';
import { toast } from 'sonner';

function Testingcomponent(props) {
    const handleSubmit = (event) => {
        event.preventDefault();
        toast.success('Success!');
    }
    return (
        <form>
            <button className='btn' onClick={handleSubmit}> Test button </button>
        </form>
    );
}

export default Testingcomponent;
