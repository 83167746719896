import React, { useEffect, useState } from "react";
import AssignmentList from "../components/assigments/assignmentList";
import { getRecords } from "../api/crud";
import PocketBase from "pocketbase";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import SerchbarAss from "../components/assigments/serchbarAss";
import SortAss from "../components/assigments/sortAss";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

function Assignments() {

    const [unfiltredRecords, setUnfiltredRecords] = useState([]);
    const [sortRecords, setSortRecords] = useState([]);
    const [serchRecords, setSerchRecords] = useState([]);
    const [clientList, setClientList] = useState(new Map());
    const [archive, setArchive] = useState(false);
    const [hassignment_id, setHassignment_id] = useState(0);

    const handleSubmit = (e) => e.preventDefault();

    const handleChange = () => {
        setArchive(!archive);
    }

    const setHAssignmentId = async () =>{
        const record = await pb.collection('assignments').getFirstListItem("", {sort:"-old_assign_id"});
        setHassignment_id(record.old_assign_id)
        return record.old_assign_id
    }

    const fetchPageRecords = async (page, perPage) => {
        //return await pb.collection('ass').getList(page, perPage);
        return await pb.collection("assignment_stats").getList(page,perPage,{ filter: 'a_date >= "2015-12-29"' , sort:"-client_id", requestKey: null});

    };

    const loadAndAppendRecords = async () => {
        const perPage = 500;
        let page = 1;

        // Erste Seite laden
        const initialResponse = await fetchPageRecords(page, perPage);
        let allItems = [...initialResponse.items];

        const sortedRecords = allItems.sort((a, b) => {
            const clientCompare = a.client_name.localeCompare(b.client_name);
            return clientCompare !== 0 ? clientCompare : a.assignments_name.localeCompare(b.assignments_name);
        });

        setUnfiltredRecords(sortedRecords);
        setSerchRecords(sortedRecords.filter(post => !post.archived));

        // Restliche Seiten im Hintergrund laden
        while (page < initialResponse.totalPages) {
            page++;
            const response = await fetchPageRecords(page, perPage);
            allItems = [...allItems, ...response.items];

            const updatedSortedRecords = allItems.sort((a, b) => {
                const clientCompare = a.client_name.localeCompare(b.client_name);
                return clientCompare !== 0 ? clientCompare : a.assignments_name.localeCompare(b.assignments_name);
            });

            setUnfiltredRecords(updatedSortedRecords);
            setSerchRecords(updatedSortedRecords.filter(post => !post.archived));
        }
    };


    useEffect(() => {
        async function tablesToMap(val, tabName) {
            var data = await pb.collection(tabName).getFullList(400)
            for (var i in data) {
                val.set(data[i].id, data[i].client_name)
            }
        }

        tablesToMap(clientList, "clients")

        const loadData = async () => {
            await setHAssignmentId();
            await loadAndAppendRecords();
        };

        loadData();

        /*getRecords("assignments","").then(json => {
            setHAssignmentId()
            setUnfiltredRecords(json.sort((a, b) => {
                const clientCompare = a.client_name.localeCompare(b.client_name);
                return clientCompare !== 0 ? clientCompare : a.assignments_name.localeCompare(b.assignments_name);
            }))
            return json
        }).then(json => {
            setSerchRecords(json.filter(post => !post.archived))
        })
        console.log("refetched")*/
    }, [])
    return (
        <div aria-disabled="!isValide" className="App w-full bg-plat">
            <div className="flex flex-col h-screen w-full">
                <div className="flex flex-col h-16 border-black h-20 border-b bg-base-100 ">
                    <div className="flex justify-between align-middle">
                        <div className="relative top-5">
                            <h1 className="text-3xl font-medium text-black w-40 px-5">Aufträge</h1>
                        </div>
                        <div className="grid grid-flow-col my-4">
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-flow-col gap-2 my-3.5 mx-5">
                                    <div className="flex justify-center">
                                        <label className="absolute text-xs -translate-y-4 mx-3">Archive</label>
                                        <input className="toggle toggle-accent" type="checkbox" checked={archive} value={archive} onChange={handleChange} />
                                    </div>
                                </div>
                            </form>
                            <SerchbarAss posts={unfiltredRecords} serchRecords={serchRecords} setSerchRecords={setSerchRecords} archive={archive} />
                            <label className="btn btn-outline btn-circle rounded-full mx-5 swap swap-rotate">
                                <input type="checkbox" onClick={() => document.getElementById('modalAssAdd-Generel').showModal()} />
                                <AiOutlinePlus className="swap-off text-2xl"></AiOutlinePlus>
                                <AiOutlineClose className="swap-on text-2xl" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center overflow-auto h-full">
                    {unfiltredRecords.length !== 0 ? <AssignmentList records={serchRecords} unfRec={unfiltredRecords} clientList={clientList} hassignment_id={hassignment_id} setSearchRecords={setSerchRecords} setUnfiltredRecords={setUnfiltredRecords} /> : <span className="loading loading-dots loading-lg text-primary">Loading</span>}
                </div>
            </div>
        </div>
    );
}

export default Assignments;
