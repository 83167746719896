import React from 'react';
import {AiOutlineClose, AiOutlineSend} from "react-icons/ai";
import {update} from "../../api/crud";
import DatePicker from "react-datepicker";
import PocketBase from "pocketbase";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');


function AssignmentEdit({editData,setEditdata,setEditVis,clientList,editVis, setUnfiltredRecords, setSearchRecords}) {

    const handleChange = (val,e) => {
        let obj = {}
        obj = {
            [val]: e.target.value
        };
        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    const handleUpdate = async (data) => {
        update("assignments", data);
        const newRecord = await pb.collection('assignment_stats').getOne(data.id)
        setSearchRecords(prevRecords => [...prevRecords].filter(post => post.id !== data.id));
        setUnfiltredRecords(prevRecords => [...prevRecords].filter(post => post.id !== data.id));
        setSearchRecords(prevRecords => [...prevRecords, newRecord]);
        setUnfiltredRecords(prevRecords => [...prevRecords, newRecord]);
        setEditVis(false)
    }

    return (
        <div className="border border-gray-700 grid grid-cols-8 h-16 mb-6 bg-white text-black rounded-2xl items-center">
            <input name="discEdite" type="text" id="discEdit" value={editData.assignments_name} onChange={(e) => handleChange("assignments_name",e)} className="rounded text-xl border w-auto mr-10 col-start-1 col-span-2 mx-5"/>
            <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5" value={editData.client_id} id="CLietEdit" onChange={(e) => handleChange("client_id",e)}>{Array.from(clientList).map(([value, key])=> (<option key={key} value={value}>{key}</option>))}</select>
            <div id="DateEdit" className="w-full p-2.5 text-center">{(editData.a_date)}</div>
            <input name="discEdite" type="number" id="discEdit" value={editData.time_limit} onChange={(e) => handleChange("time_limit",e)} className="rounded text-md border w-auto mr-10"/>
            <input name="discEdite" type="number" id="discEdit" value={editData.budget} onChange={(e) => handleChange("budget",e)} className="rounded text-md border w-auto mr-10"/>
            <input name="discEdite" type="number" id="discEdit" value={editData.hourRate} onChange={(e) => handleChange("hourRate",e)} className="rounded text-md border w-auto mr-10"/>
            <div className="flex justify-center">
                    <button onClick={() => handleUpdate(editData)} className="flex justify-center hover:text-maxrange text-3xl mx-3"><AiOutlineSend/></button>
                    <button onClick={() => setEditVis(!editVis) && setEditdata({})} className="flex justify-center hover:text-maxrange text-3xl mx-3"><AiOutlineClose/></button>
                </div>
        </div>
    );
}

export default AssignmentEdit;
