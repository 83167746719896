import React, {useEffect, useState} from 'react';

const SumHour = ({records}) => {

    var sumGes = 0
    var sumFakt = 0
    for (var i = 0; i < records.length; i++){
        sumGes += records[i].time
        if (records[i].paid){
            sumFakt += records[i].time
        }
    }

    return (
        <div className="flex justify-center self-center border-x px-10"><label className="absolute text-xs -translate-y-4 mx-3">Stunden</label><p>Fakt: <u className="text-success">{sumFakt.toFixed(2)}</u><br />Ges: {sumGes.toFixed(2)}</p></div>
    );
};

export default SumHour;
