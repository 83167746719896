import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import {add} from "../../api/crud";
import {AiOutlineSend} from "react-icons/ai";
import PocketBase from "pocketbase";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');
const ClientAdd = ({records}) => {

    const [editData, setEditdata] = useState({});

    const handleSubmit = (e) => e.preventDefault();
    const handleChange = (val,e) => {
        let obj= {
                pm: pb.authStore.model.id,
                old_client_id: records[0].old_client_id + 1,
                [val]: e.target.value
            };
        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    return(
        <>
            <form onSubmit={handleSubmit}>
                <dialog id="modalAdd-Generel" className="modal modal-bottom sm:modal-middle">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Grundlegende Infos</h3>
                        <div className="divider"/>
                        <div className="form-control grid grid-cols-2 gap-2">
                            <input type="text" placeholder="Kunden Name" className="input input-bordered w-full max-w-xl col-span-2" onChange={(e) => handleChange("client_name",e)}/>
                            <input type="text" placeholder="Umsatzsteuer ID" className="input input-bordered w-full max-w-xs" onChange={(e) => handleChange("ustID",e)}/>
                            <input type="text" placeholder="Kunden Nummer" className="input input-bordered w-full max-w-xs" onChange={(e) => handleChange("client_number",e)}/>
                        </div>
                        <div className="modal-action">
                            <form method="dialog">
                                <button className="btn" onClick={()=>document.getElementById('modalAdd-Prio').showModal()}>Weiter</button>
                            </form>
                        </div>
                    </div>
                </dialog>
                <dialog id="modalAdd-Prio" className="modal modal-bottom sm:modal-middle">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Prio Auswahl</h3>
                        <div className="divider"/>
                        <div className="flex justify-center grid grid-cols-1 gap-5">
                            <div className="join">
                                <input className="join-item btn" type="radio" name="options" aria-label="Klein-Kunde" value="75" onChange={(e) => handleChange("prio_score",e)}/>
                                <input className="join-item btn" type="radio" name="options" aria-label="Normal-Kunde" value="100" onChange={(e) => handleChange("prio_score",e)}/>
                                <input className="join-item btn" type="radio" name="options" aria-label="Groß-Kunde" value="150" onChange={(e) => handleChange("prio_score",e)}/>
                            </div>
                            <input type="text" placeholder="Umsatz vergangenes Jahr" className="input input-bordered w-full max-w-xs" onChange={(e) => handleChange("approx_sales_LJ",e)}/>
                        </div>
                        <div className="modal-action">
                            <form method="dialog">
                                <button className="btn" onClick={()=>document.getElementById('modalAdd-ApproxSales').showModal()}>Weiter</button>
                            </form>
                        </div>
                    </div>
                </dialog>
                <dialog id="modalAdd-ApproxSales" className="modal modal-bottom sm:modal-middle">
                    <div className="modal-box">
                        <h3 className="font-bold text-lg">Notizen zum Kunden</h3>
                        <div className="divider"/>
                        <textarea className="py-4 textarea textarea-bordered w-full" onChange={(e) => handleChange("note",e)} placeholder='Beispiel: "Kunde hat ein Rabat" oder "Bitte .... beruecksichtigen"...'></textarea>
                        <div className="modal-action">
                            <form method="dialog">
                                {/* if there is a button in form, it will close the modal */}
                                <button className="btn" onClick={() => add("clients",editData,setEditdata)}>Anlegen</button>
                            </form>
                        </div>
                    </div>
                </dialog>
            </form>
        </>
        /*
        <div className="border border-gray-700 grid grid-cols-10 h-16 mb-6 bg-white text-black rounded-2xl shadow-2xl items-center">
            <textarea name="discEdite" id="discEdit" onChange={(e) => handleChange("discr",e)} className="text-md font-bold border w-auto mx-5 col-start-1 col-span-2 rounded"/>
            <DatePicker dateFormat="yyyy/MM/dd" id="DateEdit" className="border rounded w-full p-2.5 text-center" selected={nstartDate} onChange={(e) => handleChange("date",e)}/>
            <p className="flex justify-center">{pb.authStore.model.name}</p>
            <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5 " id="InvoiceEdit" onChange={(e) => handleChange("invoice",e)}><option hidden value="">Abrechnung</option>{Array.from(invList).map(([value, key])=> (<option key={key} value={value}>{key}</option>))}</select>
            <div id="TimeEdit" className="flex justify-center col-span-2 w-5/6">
                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-l-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={(e) => handleChange("time",e)}>{Array.from({length:17}).map((it,index) => <option key={index} value={index}>{index}</option>)}</select>
                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-r-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={(e) => handleChange("time",e)}>
                    <option value={0.16}>10 Min</option>
                    <option value={0.25}>15 Min</option>
                    <option value={0.33}>20 Min</option>
                    <option value={0.50}>30 Min</option>
                    <option value={0.75}>45 Min</option>
                </select>
            </div>
            <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5" id="CLietEdit" onChange={(e) => handleChange("client",e)}><option hidden value="">Kunde</option>{Array.from(clientList).map(([value, key])=> (<option key={key} value={value}>{key}</option>))}</select>
            <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5" id="AssignmentEdit" onChange={(e) => handleChange("assignment",e)}><option hidden value="">Auftrag</option>{Array.from(assignmentList).map(([value, key])=> (<option key={key} value={value}>{key}</option>))}</select>
            <button onClick={() => add(editData,setEditdata)} className="flex justify-center hover:text-maxrange text-3xl mx-3"><AiOutlineSend/></button>
        </div>*/
    )
};

export default ClientAdd;

