import React from 'react';
import {VictoryPie, VictoryChart, VictoryTheme} from "victory";
import CircularProgressBar from "./circularProgressbar";

function MostWorked({records}) {

    const mostWorktMap = new Map()
    for (var i in records){
        if(!mostWorktMap.has(records[i].expand.client.client_name)){
            mostWorktMap.set(records[i].expand.client.client_name,records[i].time)
        } else {
            mostWorktMap.set(records[i].expand.client.client_name,(mostWorktMap.get(records[i].expand.client.client_name) + records[i].time))
        }
    }
    const mostWorktArr = Array.from(mostWorktMap).sort((a, b) => {
        return a[1] < b[1]
    });
    console.log(mostWorktArr)


    return (
    <>
        <div className="overflow-x-auto">
            <table className="table">
                {/* head */}
                <thead>
                <tr>
                    <th>Kunde</th>
                    <th>Zeit</th>
                </tr>
                </thead>
                <tbody>
        {mostWorktArr.map((data, index)=>(
            <tr>
                <th>{data[0]}</th>
                <td>{data[1].toFixed(2)}h</td>
            </tr>
        ))}
                </tbody>
            </table>
        </div>
    </>
    );
}

export default MostWorked;
