import React, { useState, useEffect, useMemo } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

const SerchbarAss = ({ posts, setSerchRecords, archive }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortKey, setSortKey] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortKey(e.target.value);
    };

    const sortedAndSearchedPosts = useMemo(() => {
        let resultArray = [...(posts.filter(post => archive ? post.archived && post.a_date >= "2023-12-29" : !post.archived))];
        console.log(resultArray.length)

        // Search
        if (searchTerm) {
            resultArray = resultArray.filter(post =>
                ((post.assignments_name.toLowerCase()).includes(searchTerm.toLowerCase()) ||
                    (post.client_name.toLowerCase()).includes(searchTerm.toLowerCase()))
            );
        }

        // Sort
        if (sortKey) {
            resultArray.sort((a, b) => {
                if (sortKey === "client_name") {
                    const clientCompare = a.client_name.localeCompare(b.client_name);
                    return clientCompare !== 0 ? clientCompare : a.assignments_name.localeCompare(b.assignments_name);
                } /*else if (sortKey === "created") {
                    return new Date(a.created) - new Date(b.created);
                }*/ else if (sortKey === "assignments_name") {
                    return a.assignments_name.localeCompare(b.assignments_name);
                } else if (sortKey === "time_limit") {
                    return a.time_limit - b.time_limit;
                } else if (sortKey === "pm") {
                    return a.pm.localeCompare(b.pm)
                }
            });
        }

        if (sortDirection === 'desc') {
            resultArray.reverse();
        }

        return resultArray;
    }, [posts, searchTerm, sortKey, sortDirection, archive]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setSerchRecords(sortedAndSearchedPosts);
        }, 0); // Verzögerung von 300 ms für die Suche

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, sortKey, sortDirection, archive, posts]);

    return (
        <form className="grid grid-cols-7 gap-2" onSubmit={(e) => e.preventDefault()}>
            <input className="input w-full max-w-xs input-bordered col-span-4" placeholder="Search" type="text" onChange={handleSearchChange} />
            <select className="select w-full max-w-xs input-bordered col-span-2" onChange={handleSortChange}>
                <option value="">Sort by...</option>
                <option value="assignments_name">Name</option>
                <option value="client_name">Client Name</option>
                {/*<option value="created">Created</option>*/}
                <option value="time_limit">Time Limit</option>
                <option value="pm">PM</option>
            </select>
            <button className="btn btn-primary col-span-1" onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}>
                {sortDirection === 'asc' ? '↑' : '↓'}
            </button>
        </form>
    );
};

export default SerchbarAss;
