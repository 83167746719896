import { useState, useEffect } from 'react';
import axios from "axios";
export default function DBSync(){
    axios.post('https://dbsyncer.ma-x.de/syncTrigger',true )
        .then(function (response) {
            console.log("Sync")
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
}
