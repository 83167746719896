import React, {useEffect, useState} from "react";
import ClientList from "../components/clients/clientList";
import SerchbarClient from "../components/clients/serchbarClient";
import {AiOutlineClose, AiOutlinePlus} from "react-icons/ai";
import {getRecords} from "../api/crud";
import ClientAdd from "../components/clients/clientAdd";

function Clients() {
    const [unfiltredRecords, setUnfiltredRecords] = useState([])
    const [serchRecords, setSerchRecords] = useState([]);
    const [clientAdd, setClientAdd] = useState();

    useEffect(() => {
        getRecords("clients").then(json => {
            setUnfiltredRecords(json)
            return json
        }).then(json =>{
            setSerchRecords(json)
        })
        //clientSync()
    }, [])
    return (
        <div aria-disabled="!isValide" className="App w-full bg-plat">
            <div className="flex flex-col h-screen w-full">
                <div className="flex flex-col h-16 border-black h-20 border-b bg-base-100 ">
                    <div className="flex justify-between align-middle">
                        <div className="relative top-5">
                            <h1 className="text-3xl font-medium text-black w-40 px-5">Kunde</h1>
                        </div>
                        <div className="grid grid-flow-col my-4">
                            <SerchbarClient posts={unfiltredRecords} setSerchRecords={setSerchRecords}/>
                            <label className="btn btn-outline btn-circle rounded-full mx-5 swap swap-rotate">
                                <input type="checkbox" onClick={()=>document.getElementById('modalAdd-Generel').showModal()}/>
                                <AiOutlinePlus className="swap-off text-2xl"></AiOutlinePlus>
                                <AiOutlineClose className="swap-on text-2xl"/>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center overflow-scroll h-full">
                    <ClientAdd records={serchRecords} hidden></ClientAdd>
                    {unfiltredRecords.length !== 0 ? <ClientList records={serchRecords}></ClientList> :
                        <span className="loading loading-dots loading-lg text-primary">Loading</span>}
                </div>
            </div>
        </div>
    );
}

export default Clients;
