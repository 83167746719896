import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {del, getOne, getRecords} from "../api/crud";
import SerchbarAss from "../components/assigments/serchbarAss";
import PocketBase from "pocketbase";
import {AiOutlineClose, AiOutlineDelete, AiOutlineEdit, AiOutlinePlus} from "react-icons/ai";
import AssignmentList from "../components/assigments/assignmentList";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');


function ClientsAssignments() {
    const {clientId} = useParams()
    const [unfiltredRecords, setUnfiltredRecords] = useState([])
    const [serchRecords, setSerchRecords] = useState([]);
    const [client, setClient] = useState([]);
    const [hourAdd, setHourAdd] = useState(false);
    const [multiEdit, setMultiEdit] = useState(false);
    const [clientList, setClientList] = useState(new Map())
    const [archive, setArchive] = useState(false);

    const handleChange = () => {
        setArchive(!archive);
    }

    const handleSubmit = (e) => e.preventDefault()
    var sumGes = 0
    var sumFakt = 0
    for (var i = 0; i < serchRecords.length; i++){
        sumGes += serchRecords[i].time
        if (serchRecords[i].paid){
            sumFakt += serchRecords[i].time
        }
    }

    useEffect(() => {
        async function tablesToMap(val,tabName){
            var assignment = await pb.collection(tabName).getFullList(400)
            for (var i in assignment){
                val.set(assignment[i].id, assignment[i].name || assignment[i].client_name || [assignment[i].assignments_name, assignment[i].client_id] || assignment[i].invoice )
            }
        }

        //tablesToMap(clientList, "clients")
        getRecords("assignments",('client_id="'+clientId+'"'), "client").then(json => {
            setUnfiltredRecords(json)
            return json
        }).then( json => {
            setSerchRecords(json)
        })
        getOne("Clients", clientId).then(json => {
            setClient(json)
        })
    },[])

    return (
        <div aria-disabled="!isValide" className="App w-full bg-plat">
            <div className="flex flex-col h-screen w-full">
                <div className="flex flex-col border-black h-20 border-b bg-white ">
                    <div className="flex justify-between align-middle">
                        <div className="relative self-center border-r w-1/5">
                            <h1 className="text-2xl font-medium text-black mx-5">{client.client_name}</h1>
                        </div>
                        <div className="grid grid-flow-col my-4">
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-flow-col gap-2 my-3.5 mx-5">
                                    <div className="flex justify-center">
                                        <label className="absolute text-xs -translate-y-4 mx-3">Archive</label>
                                        <input className="toggle toggle-accent" type="checkbox" checked={archive}
                                               value={archive} onChange={handleChange}/>
                                    </div>
                                </div>
                            </form>
                            <SerchbarAss posts={unfiltredRecords} serchRecords={serchRecords}
                                         setSerchRecords={setSerchRecords} archive={archive}/>
                            <div className="flex justify-center self-center b px-3">
                                {multiEdit && (<button className="btn btn-outline btn-xs absolute translate-y-5"
                                                       onClick={() => document.getElementById('multiEditModal').showModal()}>Multi
                                    Edit</button>)}
                                <label
                                    className={multiEdit ? ("btn btn-outline btn-circle btn-sm rounded-full mx-5 swap swap-rotate -translate-y-5") : ("btn btn-outline btn-circle rounded-full mx-5 swap swap-rotate")}>
                                    <input type="checkbox" onChange={() => setHourAdd(!hourAdd)}/>
                                    <AiOutlinePlus className="swap-off text-2xl"></AiOutlinePlus>
                                    <AiOutlineClose className="swap-on text-2xl"/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center overflow-auto h-full">
                    <AssignmentList records={serchRecords} unfRec={unfiltredRecords} clientList={clientList}/>
                </div>
            </div>
        </div>
    );
}

export default ClientsAssignments;
