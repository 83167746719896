import React, { useEffect, useState, } from 'react';
import PocketBase from "pocketbase";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SerchbarHour from "./serchbarHour";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineSend} from "react-icons/ai";
import {add, OldDBAdd, update} from "../../api/crud";
import Hour from "./hour";
import HourAdd from "./hourAdd";
import HourEdit from "./hourEdit";
import HourTabbleTest from "./hourTabbleTest";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const Hourlist = ({serchRecords, setSerchRecords, setUnfiltredRecords, htime_id, setHtime_id, filter, setMultiEdit, hourAdd, setHourAdd, persList,invList,clientList,assignmentList}) => {

    const [editVis, setEditVis] = useState(false);
    const [editData, setEditdata] = useState({});
    const [startDate, setStartDate] = useState(editData.date);
    const [nstartDate, setNStartDate] = useState(new Date());

    const handleChange = (val,e) => {
        let obj = {}
        if (val === "date"){
            obj = {
                date: e
            };
        } else if (val === "time"){
            if (editData.time != null){
                if (e.target.value < 1){
                    obj = {
                        time: (parseInt(editData.time.toString().replace(/.\d+/, "")) + parseFloat(e.target.value))
                    };
                } else {
                    obj = {
                        time: (parseFloat(e.target.value) + parseFloat("0.".concat(((editData.time.toString()).split("."))[1])))
                    }
                }
            } else {
                obj = {
                    time: e.target.value
                }
            }
        } else {
            obj = {
                person: pb.authStore.model.id,
                [val]: e.target.value
            };
        }
        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    const edit = async (editId) => {
        setEditVis(!editVis)
        const gettetOne = await pb.collection('time').getOne(editId);
        setEditdata(gettetOne)
    };

    return (
       <div className=" w-full relative m-4">
           {hourAdd && (<HourAdd
               invList={invList}
               clientList={clientList}
               assignmentList={assignmentList}
               htime_id={htime_id}
               filter={filter}
               setHourAdd={setHourAdd}
               setSearchRecords={setSerchRecords}
               setUnfiltredRecords={setUnfiltredRecords}
               setHtime_id={setHtime_id}
           />)}
           <Hour
               serchRecords={serchRecords}
               setSearchRecords={setSerchRecords}
               setUnfiltredRecords={setUnfiltredRecords}
               assignmentList={assignmentList}
               editData={editData}
               setEditdata={setEditdata}
               editVis={editVis}
               clientList={clientList}
               setMultiEdit={setMultiEdit}
               setEditVis={setEditVis}
               invList={invList}
               persList={persList}
               edit={edit}
           />
       </div>
    );
}

export default Hourlist;
