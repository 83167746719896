import React, {useEffect, useState} from "react";
import TimeWorkChart from "../components/charts/timeWorkChart";
import TimeMonthChart from "../components/charts/timeMonthChart";
import MonthGoalPieChart from "../components/charts/monthGoalPieChart";
import MostWorked from "../components/charts/mostWorked";
import YearGoalPieChart from "../components/charts/yearGoalPieChart";
import {getRecords} from "../api/crud";
import PocketBase from "pocketbase";
import {da} from "date-fns/locale";
import {AiOutlineHistory} from "react-icons/ai";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

function Dashboard() {

    const date = new Date()
    const month = ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","October","November","Dezember"];
    const [records, setRecords] = useState([]);
    const [recordsMonth, setRecordsMonth] = useState([]);
    const monthFrom = `${date.getFullYear()}-${(((date.getMonth()+1).toString()).length != 1) ? "":"0"}${date.getMonth()+1}`;
    console.log(monthFrom)
    var monthHours = 0;
    var monthHoursFakt = 0;
    var yearHours = 0;
    var yearHoursFakt = 0;

    for (var i = 0; i < records.length; i++){
        yearHours += records[i].time
        if(records[i].paid){
            yearHoursFakt += records[i].time
        }
    }
    for (var i = 0; i < recordsMonth.length; i++){
        monthHours += recordsMonth[i].time
        if(recordsMonth[i].paid){
            monthHoursFakt += records[i].time
        }
    }

    const plusminYear = (((pb.authStore.model.worktime_account-pb.authStore.model.vacation) / 12) * (date.getMonth()+1)) < yearHours;
    const plusminMonth = (((pb.authStore.model.worktime_account-pb.authStore.model.vacation) / 12)) < monthHours;



    useEffect(() => {
        getRecords("time", ('date >= "' + date.getFullYear() + '" && person ="'+ pb.authStore.model.id + '"'), "").then(json =>{
            setRecords(json)
            setRecordsMonth(json.filter(post => post.date > monthFrom))
        })

    }, []);
    return (
        <div aria-disabled="!isValide" className="App w-full bg-plat overflow-auto">
            <div className="flex flex-col h-screen w-full">
                <div className="h-20 border-black border-b bg-white flex">
                    <h1 className="text-3xl font-medium text-black w-50 px-5 self-center">Wilkommen <b>{pb.authStore.model.username.replace("_"," ")}</b></h1>
                </div>
                <div className="grid grid-rows-1 justify-evenly grid-flow-col h-1/5 my-5 mx-5">
                    <div className="stats stats-vertical lg:stats-horizontal shadow">
                        <div className="stat">
                            <div className="stat-figure text-secondary">
                                <div className="radial-progress text-accent" style={{"--value":(monthHours / ((pb.authStore.model.worktime_account-pb.authStore.model.vacation)/12) * 100),"--size": "3.5rem", "--thickness": "0.5rem"}} role="progressbar">{(monthHours / ((pb.authStore.model.worktime_account-pb.authStore.model.vacation)/12) * 100).toFixed()}%</div>
                            </div>
                            <div className="stat-title">Monatliche Stunden</div>
                            <div className="stat-value">{monthHours.toFixed(2)}</div>
                            <div className={`stat-desc ${plusminMonth ? "text-success" : "text-error"}`}> {plusminMonth ? "↗︎" : "↘︎"} {((pb.authStore.model.worktime_account-pb.authStore.model.vacation) / 12).toFixed()} | {(monthHours - ((pb.authStore.model.worktime_account-pb.authStore.model.vacation) / 12)).toFixed()}</div>
                        </div>

                        <div className="stat">
                            <div className="stat-figure text-accent">
                                <AiOutlineHistory className="text-3xl"/>
                            </div>
                            <div className="stat-title">Faktorierbar</div>
                            <div className="stat-value">{monthHoursFakt.toFixed(2)} ({(monthHoursFakt/monthHours*100).toFixed()}%)</div>
                            <div className="stat-desc"> {yearHoursFakt.toFixed(2)} ({(yearHoursFakt/yearHours*100).toFixed()}%)</div>
                        </div>

                        <div className="stat overflow-x-auto">
                            <div className="stat-title">Most Workt ({month[date.getMonth()]})</div>
                            <div className="stat-value"><MostWorked records={recordsMonth}/></div>
                        </div>

                        <div className="stat">
                            <div className="stat-figure text-secondary">
                                <div className="radial-progress text-accent" style={{"--value":(yearHours / pb.authStore.model.worktime_account * 100),"--size": "3.5rem", "--thickness": "0.5rem"}} role="progressbar">{(yearHours / pb.authStore.model.worktime_account * 100).toFixed()}%</div>
                            </div>
                            <div className="stat-title">Stunden Konto</div>
                            <div className="stat-value">{yearHours.toFixed(2)}</div>
                            <div className={`stat-desc ${plusminYear ? "text-success" : "text-error"}`}> {plusminYear ? "↗︎" : "↘︎"} {(((pb.authStore.model.worktime_account-pb.authStore.model.vacation) / 12) * (date.getMonth()+1)).toFixed()} | {(yearHours - (((pb.authStore.model.worktime_account-pb.authStore.model.vacation) / 12) * (date.getMonth()+1)).toFixed()).toFixed()}</div>
                        </div>
                    </div>
                </div>
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 h-1/2 w-full ">
                    <div className="mx-5 md:ml-5 p-[5%] shadow-2xl rounded-2xl border"><TimeWorkChart records={records}/></div>
                    <div className="mx-5 md:mr-5 p-[5%] shadow-2xl rounded-2xl border"><TimeMonthChart records={records}/></div>
                </div>

            </div>
        </div>
    );
}

export default Dashboard;
