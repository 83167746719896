import React, { useState } from 'react';
import {add, del, getOne, update} from "../../api/crud";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineFile } from "react-icons/ai";
import Editor from 'react-simple-wysiwyg';
import { Link } from "react-router-dom";
import TimeWorkChart from "../charts/timeWorkChart";
import PocketBase from "pocketbase";
import AssignmentExpand from "./assignmentExpand";
import AssignmentEdit from "./assignmentEdit";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

function Assignment({ records, updCheckboxes, edit, editVis, setEditVis, editdataEdit, setEditdata, clientList, setSearchRecords, setUnfiltredRecords}) {
    const parser = new DOMParser();
    const [editData, setEditData] = useState({});
    const [editID, setEditID] = useState();
    const [expandedId, setExpandedId] = useState(null); // Verwaltet die ID der aktuell erweiterten Box

    const editNote = async (id, data) => {
        const newRecord = await pb.collection('assignments').getOne(id).then(res => setEditData(res));
        //setEditData(newRecord);
        setEditID(id);
        document.getElementById('editNote').showModal();
    };

    const handleChange = (val, e) => {
        let obj = {
            [val]: encodeHTML(e.target.value)
        };
        setEditData(editData => ({ ...editData, ...obj }));
    };

    const handleDelete = async (data) => {
        del('assignments', data.id, data.old_assign_id);
        setSearchRecords(prevRecords => [...prevRecords].filter(post => post.id !== data.id));
        setUnfiltredRecords(prevRecords => [...prevRecords].filter(post => post.id !== data.id));
    }

    const saveNote = () => {
        update("assignments", editData);
    };

    const toggleExpanded = (id) => {
        //setEditData(getOne("assignments", id, "client_id,pm"))
        setExpandedId(expandedId === id ? null : id);
    };

    const checkEscalation = (data, use) => {
        const getReturnValue = (usedBudget) => {
            if (usedBudget >= 1) return (use === "bg" ? 'bg-red-200' : 'text-red-500');
            if (usedBudget >= 0.9) return (use === "bg" ? 'bg-orange-200' : 'text-orange-400');
            if (usedBudget >= 0.75) return (use === "bg" ? 'bg-yellow-200' : 'text-yellow-500');
            return use === "bg" ? 'bg-base-100' : 'text-primary';
        };

        //console.log(((data.hourRate * data.total_paid)))

        if (data.budget !== 0) {
            const usedBudget = (data.hourRate * data.total_paid) / data.budget;
            return getReturnValue(usedBudget);
        }

        if (data.budget === 0) {
            if (data.time_limit === 0) {
                return getReturnValue(0.5);
            }
            const usedBudget = data.total_paid / data.time_limit;
            return getReturnValue(usedBudget);
        }

        return 'bg-base-100 text-primary';
    };

    const getOneAssignment = async (id) => {
        setEditData(getOne("assignments", id, "client_id,pm"));
    };

    function encodeHTML(text) {
        if (text === "<br>") {
            return "";
        } else {
            return text.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
                return '&#' + i.charCodeAt(0) + ';';
            });
        }
    }

    //console.log(records)

    return (
        <>
            {records.map((data, index) => (
                <>
                {editVis && editdataEdit.id === data.id ? (
                    <AssignmentEdit
                        editData={editdataEdit}
                        setEditdata={setEditdata}
                        clientList={clientList}
                        editVis={editVis}
                        setEditVis={setEditVis}
                        setUnfiltredRecords={setUnfiltredRecords}
                        setSearchRecords={setSearchRecords}
                    />
                    ):(
                        <div
                            key={index}
                            className={`grid mb-6 bg-base-100 text-primary border border-primary rounded-2xl shadow-2xl transition-all duration-500 ${
                                expandedId === data.id ? 'h-64 grid-rows-4' : 'h-16 grid-rows-1'}`}
                        >
                            <div
                        className={`grid gap-5 ${checkEscalation(data, "bg")} h- rounded-2xl grid-cols-8 grid-flow-col items-center ${
                            expandedId === data.id && 'border-b border-primary'
                        }`}
                        onClick={() => toggleExpanded(data.id, data)}
                    >
                        <div className="pl-5 col-start-1">
                            <input
                                type="checkbox"
                                className="checkbox checkbox-MD checked:checkbox-accent form-checkbox rowCheckbox focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <div
                            className="text-md font-bold col col-start-2 col-span-2 tooltip tooltip-left text-left h-5/6 overflow-hidden hover:overflow-auto"
                            data-tip="Name"
                        >
                            <Link to={'/assignment/' + data.id}>{data.assignments_name}</Link>
                        </div>
                        <div className="col-span-2 tooltip text-start tooltip-left" data-tip="Kunde">
                            <Link to={'/client/' + data.client_id}>{data.client_name}</Link>
                        </div>
                        <div className="flex justify-center tooltip" data-tip="Projekt Manager">
                            {data.pm}
                        </div>
                        <div className="flex justify-center tooltip" data-tip="Gesamt / Fakturierbar">
                            <label className="absolute text-xs -translate-y-4">Stunden</label>
                            {data.total ? data.total.toFixed(2) : 0} / {data.total_paid.toFixed(2)}
                        </div>
                        <div className="tooltip" data-tip="Anfangs Datum">
                            {data.a_date.split(' ')[0]}
                        </div>

                        <div className="flex justify-center">
                            <div>
                                {data.note.length !== 0 ? (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            editNote(data.id, data);
                                        }}
                                        className="text-maxrange hover:text-maxrange text-3xl mx-3 noteTooltip"
                                    >
                                        <AiOutlineFile />
                                        <span
                                            className="tooltiptext"
                                            dangerouslySetInnerHTML={{
                                                __html: new DOMParser().parseFromString(data.note, 'text/html').body
                                                    .textContent,
                                            }}
                                        />
                                    </button>
                                ) : (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            editNote(data.id, data);
                                        }}
                                        className="hover:text-maxrange text-3xl mx-3"
                                    >
                                        <AiOutlineFile />
                                    </button>
                                )}
                            </div>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDelete(data);
                                }}
                                className="hover:text-maxrange text-3xl mx-3"
                            >
                                <AiOutlineDelete />
                            </button>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    edit(data.id);
                                }}
                                className="hover:text-maxrange text-3xl mx-3"
                            >
                                <AiOutlineEdit />
                            </button>
                        </div>
                    </div>
                    {expandedId === data.id && (
                        <AssignmentExpand data={data} updCheckboxes={updCheckboxes} checkEscalation={checkEscalation}/>
                    )}
                </div>)}
                </>
            ))}
            <dialog id="editNote" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Notiz zum Auftrag</h3>
                    <Editor className="textarea w-full h-52 mt-2.5"
                            value={parser.parseFromString(editData.note || "", "text/html").body.textContent}
                            onChange={e => handleChange("note", e)}></Editor>
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn mx-2" onClick={() => saveNote()}>Aktualisieren</button>
                            <button className="btn">Schließen</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    );
}

export default Assignment;
