import React, {useEffect, useState, useRef} from 'react';
import { useParams } from 'react-router-dom';
import {del,add, getOne, getRecords} from "../api/crud";
import SerchbarHour from "../components/hours/serchbarHour";
import PocketBase from "pocketbase";
import {AiOutlineClose, AiOutlineDelete, AiOutlineEdit, AiFillPrinter, AiOutlinePlus} from "react-icons/ai";
import Hourlist from "../components/hours/hourlist";
import { useReactToPrint } from 'react-to-print';
import PrintAssignment from "../components/assigments/printAssignment";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');


function Assignment() {
    const {assignmentId} = useParams()
    const filter = "";
    const [unfiltredRecords, setUnfiltredRecords] = useState([])
    const [serchRecords, setSerchRecords] = useState([]);
    const [assignment, setAssignment] = useState([]);
    const [hourAdd, setHourAdd] = useState(false);
    const [endDate, setEndDate] = useState();
    const [adate, setAdate] = useState();
    const [multiEdit, setMultiEdit] = useState(false);
    const [persList, serPersList] = useState(new Map())
    const [invList, setInvList] = useState(new Map())
    const [clientList, setClientList] = useState(new Map())
    const [assignmentList, setAssignmentList] = useState(new Map())
    const [editData, setEditData] = useState({});
    const [hassignment_id, setHassignment_id] = useState(0);
    let componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const setHAssignmentId = async () =>{
        const record = await pb.collection('assignments').getFirstListItem("", {sort:"-old_assign_id"});
        setHassignment_id(record.old_assign_id)
    }

    const handleSubmit = (e) => e.preventDefault()
    var sumGes = 0
    var sumFakt = 0
    for (var i = 0; i < serchRecords.length; i++){
        sumGes += serchRecords[i].time
        if (serchRecords[i].paid){
            sumFakt += serchRecords[i].time
        }
    }

    const handleChange = (val,e) => {
        let obj = {}
        obj = {
            [val]: e.target.value
        };
        setEditData(editData => ({...editData,...obj}))
        console.log(editData)
    };

    const updCheckboxes = async (id,box,boxVal) =>{
        let obj = {};
        if (box === "completed"){
            if (boxVal) {
                obj = {
                    [box]:boxVal,
                    e_date: new Date()
                }
            } else {
                obj = {
                    [box]:boxVal,
                    e_date: null
                }
            }
        } else if (box === "completedCheck") {
            if (assignment.recurring === true){
                setEditData(assignment)
                setEditData(editData => ({...editData,...{old_assign_id: hassignment_id + 1}}))
                setEditData(editData => ({...editData,...{a_date: new Date()}}))
                setEditData(editData => ({...editData,...{id: null}}))
                document.getElementById('recreateRecurring').showModal()
                obj = {
                    [box]:boxVal
                }
            } else {
                obj = {
                    ['completed']:boxVal
                }
            }
        } else {
            obj = {
                [box]:boxVal
            }
        }
        await pb.collection('assignments').update(id,obj)
        box === "completedCheck" && assignment.recurring === true ? console.log("reload nach schliesen des Modals") : window.location.reload()
    }

    useEffect(() => {
        async function tablesToMap(val,tabName){
            var assignment = await pb.collection(tabName).getFullList(400)
            for (var i in assignment){
                if (tabName === "invoice") {
                    (assignment[i].aktiv && val.set(assignment[i].id, assignment[i].invoice))
                }
                val.set(assignment[i].id, assignment[i].name || assignment[i].client_name || [assignment[i].assignments_name, assignment[i].client_id] )
            }
        }
        tablesToMap(invList,"invoice")
        tablesToMap(persList,"users")
        tablesToMap(clientList,"clients")
        tablesToMap(assignmentList,"assignments")

        getRecords("time",('assignment ="'+assignmentId+'"')).then(json => {
            setHAssignmentId()
            setUnfiltredRecords(json)
            return json
        }).then( json => {
            setSerchRecords(json)
        })
        getOne("assignments", assignmentId, "client_id").then(json => {
            setAssignment(json)
            setEndDate(((json.e_date).split(" "))[0])
            setAdate(((json.a_date).split(" "))[0])
        })
    },[])
    return (
        <div aria-disabled="!isValide" className="App w-full bg-plat">
            <div className="flex flex-col h-screen w-full">
                <div className="flex flex-col border-black h-20 border-b bg-white ">
                    <div className="flex justify-between align-middle">
                        <div className="relative self-center border-r w-1/5">
                            <h1 className="text-1xl font-bold  text-black mx-5">{assignment.assignments_name}</h1>
                        </div>
                        <div className="grid grid-flow-col my-4">
                            <div className="grid grid-flow-col gap-4 my-3.5 mx-5">
                                <div className="flex justify-center"><label
                                    className="absolute text-xs -translate-y-4 mx-3">Abrechenbar</label><input
                                    className="toggle toggle-accent" type="checkbox" checked={assignment.billable}
                                    value={assignment.billable}
                                    onChange={(e) => updCheckboxes(assignment.id, "billable", !assignment.billable)}/>
                                </div>
                                <div className="flex justify-center"><label
                                    className="absolute text-xs -translate-y-4 mx-3">Fertig</label><input
                                    className="toggle toggle-accent" type="checkbox" checked={assignment.completed}
                                    value={assignment.completed}
                                    onChange={(e) => updCheckboxes(assignment.id, "completedCheck", !assignment.completed)}/>
                                </div>
                                <div className="flex justify-center"><label
                                    className="absolute text-xs -translate-y-4 mx-3">Wiederkehrend</label><input
                                    className="toggle toggle-accent" type="checkbox" checked={assignment.recurring}
                                    value={assignment.paid}
                                    onChange={(e) => updCheckboxes(assignment.id, "recurring", !assignment.recurring)}/>
                                </div>
                                <div className="flex justify-center"><label
                                    className="absolute text-xs -translate-y-4 mx-3">Archiv</label><input
                                    className="toggle toggle-accent" type="checkbox" checked={assignment.archived}
                                    value={assignment.archived}
                                    onChange={(e) => updCheckboxes(assignment.id, "archived", !assignment.archived)}/>
                                </div>
                            </div>
                            <SerchbarHour posts={unfiltredRecords} setSerchRecords={setSerchRecords} filter={filter}/>
                            {multiEdit && (<button className="btn btn-outline mx-3"
                                                   onClick={() => document.getElementById('multiEditModal').showModal()}>Multi
                                Edit <AiOutlineEdit className="text-2xl"/></button>)}
                            <div className="flex justify-center self-center px-3">
                                <button className="btn btn-circle btn-outline hover:btn-primary" onClick={handlePrint}>
                                    <AiFillPrinter className="text-2xl"/></button>

                                <label className="btn btn-outline btn-circle rounded-full mx-5 swap swap-rotate">
                                    <input type="checkbox" onChange={() => setHourAdd(!hourAdd)}/>
                                    <AiOutlinePlus className="swap-off text-2xl"></AiOutlinePlus>
                                    <AiOutlineClose className="swap-on text-2xl"/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center overflow-auto h-full">
                    {unfiltredRecords.length !== 0 ?
                        <Hourlist serchRecords={serchRecords} setSerchRecords={setSerchRecords} setUnfiltredRecords={setUnfiltredRecords} persList={persList} assignmentList={assignmentList}
                                  clientList={clientList} invList={invList} hourAdd={hourAdd}
                                  setMultiEdit={setMultiEdit} filter={filter}></Hourlist> :
                        <span className="loading loading-dots loading-lg text-primary">Loading</span>}
                    <div className="hidden">
                        <PrintAssignment records={serchRecords} assignment={assignment} ref={componentRef}/>
                    </div>
                </div>
                <div className="flex justify-center w-full h-16 border-black border-t">
                    <div className="flex justify-evenly align-middle h-16">
                        <div className="flex justify-center self-center b px-3 "><label
                            className="text-sm mx-3 justify-center">Angelegt:</label>{adate}</div>
                        <div className="flex justify-center self-center b px-3 "><label
                            className="text-sm mx-3">Abgeschlossen:</label>{endDate ? endDate :
                            <p className="text-success underline">Aktiv</p>}</div>
                        <div className="flex justify-center self-center px-3 mr-3"><label
                            className="text-sm mx-3 self-center">Stunden:</label><p>Fakt: <u
                            className="text-success">{sumFakt.toFixed(2)}</u><br/>Ges: {sumGes.toFixed(2)}</p></div>
                        <div className="flex justify-center self-center b px-3 "><label
                            className="text-sm mx-3">Budget:</label>{assignment.budget !== 0 ?
                            <p className="text-success">{assignment.budget + "€"}</p> :
                            <p>{assignment.budget + "€"}</p>}
                        </div>
                        <div className="flex justify-center self-center b px-3 "><label
                            className="text-sm mx-3">Stundenlohn:</label>{assignment.budget === 0 ?
                            <p className="text-success">{assignment.hourRate + "€"}</p> :
                            <p>{assignment.hourRate + "€"}</p>}
                        </div>
                    </div>
                </div>
            </div>
            <dialog id="recreateRecurring" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg pb-5">Auftrag Wiederkehrend</h3>
                    <input type="text" placeholder="Neuer Auftrags Name" value={editData.assignments_name}
                           className="input input-bordered w-full max-w-xl col-span-1 bg-white"
                           onChange={e => handleChange("assignments_name", e)}/>
                    <div className="modal-action">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn mx-2" onClick={() => add("assignments", editData)}>Neuen auftrag
                                Anlegen
                            </button>
                            <button className="btn mx-2"
                                    onClick={() => updCheckboxes(assignment.id, "completed", !assignment.completed)}>Trotzdem
                                Abschliesen
                            </button>
                            <button className="btn mx-2 my-2">Abbruch</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>
    )
        ;
}

export default Assignment;
