import * as React from "react";
import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import {useEffect} from "react";

const COLUMNS = [
    { label: 'Person', renderCell: (item) => item.person },
    { label: 'Januar', renderCell: (item) => item.jan.toFixed(2)},
    { label: 'Februar', renderCell: (item) => item.feb.toFixed(2)},
    { label: 'März', renderCell: (item) => item.mar.toFixed(2)},
    { label: 'April', renderCell: (item) => item.apr.toFixed(2)},
    { label: 'Mai', renderCell: (item) => item.mai.toFixed(2)},
    { label: 'Juni', renderCell: (item) => item.jun.toFixed(2)},
    { label: 'Juli', renderCell: (item) => item.jul.toFixed(2)},
    { label: 'August', renderCell: (item) => item.aug.toFixed(2)},
    { label: 'September', renderCell: (item) => item.sep.toFixed(2)},
    { label: 'Oktober', renderCell: (item) => item.oct.toFixed(2)},
    { label: 'November', renderCell: (item) => item.nov.toFixed(2)},
    { label: 'December', renderCell: (item) => item.dec.toFixed(2)},
    { label: 'Total', renderCell: (item) => item.total.toFixed(2)},
];

const StatYearReport = ({records,persons}) => {
    const months = ["jan","feb","mar","apr","mai","jun","jul","aug","sep","oct","nov","dec"]
    const theme = useTheme(getTheme());
    let nodes = []
    persons.sort((a, b) => a.old_pers_id < b.old_pers_id ? -1 : 1)
    for (var i in persons){
        nodes.push(
            {
                person: persons[i].name,
                id: persons[i].id,
                month: [],
                jan: 0,
                feb: 0,
                mar: 0,
                apr: 0,
                mai: 0,
                jun: 0,
                jul: 0,
                aug: 0,
                sep: 0,
                oct: 0,
                nov: 0,
                dec: 0,
                total: 0,
            }
        )
    }
    nodes.push(
        {
            person: "Total",
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
            total: 0,
        },
    );


    useEffect(() => {
        const personNodeMap = {};
        nodes.forEach((node, index) => {
            if (node.id) {
                personNodeMap[node.id] = index;
            }
        });

        for (var i = 0; i < records.length; i++) {
            var date = new Date(records[i].date);
            date.setDate(date.getDate() - 1);
            var month = date.getMonth(); // Monat für den aktuellen Datensatz extrahieren
            var day = date.getDate(); // Tag für den aktuellen Datensatz extrahieren

            const personIndex = personNodeMap[records[i].person];

            if (personIndex !== undefined) {
                nodes[personIndex][months[month]] += records[i].time; // Daten zum entsprechenden Knoten für die Person hinzufügen
                nodes[personIndex].total += records[i].time; // Gesamtzeit für die Person aktualisieren
            }

            // Gesamtzeit für alle Personen aktualisieren
            nodes[nodes.length - 1][months[month]] += records[i].time;
            nodes[nodes.length - 1].total += records[i].time;
        }
    }, []);

    const data = { nodes };

    return (
        <>
            <CompactTable columns={COLUMNS} data={data} theme={theme}/>
        </>    );
};

export default StatYearReport;
