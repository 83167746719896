import React, {useState} from 'react';
import ReactDOM from 'react-dom';
import {Bar, BarChart, BarSeries} from "reaviz";
import {da} from "date-fns/locale";

const TimeMonthChart = ({records}) => {

    const date = new Date()
    const data = [{},{},{},{},{},{},{},{},{},{},{},{}];
    const month = ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","October","November","Dezember"];

    const calc = (records) =>{
        for (var i = 0; i < 12; i++) {
            const ndate = new Date()
            //console.log(`${ndate.getFullYear()}-${(i.toString()).length !== 1 ? "" : "0"}${i+1}`)
            const filtredRecords = records.filter(post => post.date.includes(`${ndate.getFullYear()}-${((i+1).toString()).length !== 1 ? "" : "0"}${i+1}`))
            var calctime = 0;
            for (var j in filtredRecords) {
                calctime += filtredRecords[j].time
            }
            data[i] = {key: month[i], data: calctime}
        }
    }
    calc(records)

    return (
        <BarChart data={data} series={<BarSeries colorScheme={"#ff6633"} padding={0.1} bar={<Bar />} />} />

);
}

export default TimeMonthChart;
