import React, { useEffect, useState, } from 'react';
import PocketBase from "pocketbase";
import clientSync from "../../hooks/syncer";
import DatePicker from 'react-datepicker';
import Client from "./client";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineSend, AiOutlineClose} from "react-icons/ai";
import ClientAdd from "./clientAdd";
import ClientEdit from "./clientEdit";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const ClientList = ({records}) => {
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);
    const [editVis, setEditVis] = useState(false);
    const [editData, setEditdata] = useState({});

    const handleSelectAll = e => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    const handleClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
    };

    const handleChange = (val,e) => {
        let obj = {}
        obj = {
            [val]: e.target.value
        };
        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    const del = async (id) =>{
        await pb.collection('clients').delete(id);
        window.location.reload()
    };

    const edit = async (editId) => {
        setEditVis(!editVis)
        const gettetOne = await pb.collection('clients').getOne(editId);
        setEditdata(gettetOne)
        console.log(editData)
    };

    const update = async () => {
        await pb.collection('clients').update(editData.id, editData);
        setEditVis(!editVis)
        window.location.reload()
    };

    return (
        <div className=" w-full relative m-4 ">
            {editVis && (<ClientEdit editData={editData} setEditdata={setEditdata} editVis={editVis} setEditVis={setEditVis}/>)}
            <Client records={records} edit={edit}/>
            <ClientAdd records={records}/>
        </div>
    );
}

export default ClientList;
