import React, {useEffect, useState} from "react";
import clientSync from "../hooks/syncer";
import DatePicker from "react-datepicker";
import Hourlist from "../components/hours/hourlist";
import {AiOutlineClose, AiOutlinePlus} from "react-icons/ai";
import {getRecords} from "../api/crud";
import PocketBase from "pocketbase";
import SerchbarHour from "../components/hours/serchbarHour";
import SumHour from "../components/hours/sumHour";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');


function Hours() {
    const date = new Date()
    const dayForm = `${date.getFullYear()}-${(((date.getMonth()+1).toString()).length != 1) ? "":"0"}${date.getMonth()+1}-${((date.getDate().toString()).length != 1) ? "":"0"}${date.getDate()}`;
    const monthFrom = `${date.getFullYear()}-${(((date.getMonth()+1).toString()).length != 1) ? "":"0"}${date.getMonth() +1}`;
    const yearFrom = `${date.getFullYear()}`;
    const [filter, setFilter] = useState(dayForm);
    const [hourAdd, setHourAdd] = useState(false);
    const [multiEdit, setMultiEdit] = useState(false);
    const [htime_id, setHtime_id] = useState(0);

    // States that are needed for getting Record
    const [unfiltredRecords, setUnfiltredRecords] = useState([])
    const [serchRecords, setSerchRecords] = useState([]);
    const [persList, serPersList] = useState(new Map())
    const [invList, setInvList] = useState(new Map())
    const [clientList, setClientList] = useState(new Map())
    const [assignmentList, setAssignmentList] = useState(new Map())

    const handleSubmit = (e) => e.preventDefault()

    const handleChange = (e) => {
        var resultArr = unfiltredRecords.filter(post => post.date.includes(e.target.value))
        setFilter(e.target.value)
        setSerchRecords(resultArr)
    }

    const handleChangeDatePicker = (e) => {
        const pickform = `${e.getFullYear()}-${(((date.getMonth()+1).toString()).length != 1) ? "":"0"}${e.getMonth()+1}-${((e.getDate().toString()).length != 1) ? "":"0"}${e.getDate()}`;
        var resultArr = unfiltredRecords.filter(post => post.date.includes(pickform))
        console.log(resultArr)
        console.log(pickform)
        setFilter(e)
        setSerchRecords(resultArr)
    }

    const setHtimeId = async () =>{
        const record = await pb.collection('time').getFirstListItem("", {sort:"-old_time_id"});
        setHtime_id(record.old_time_id)
        return record.old_time_id
    }

    useEffect(() => {
        async function tablesToMap(val,tabName){
            let data = (await pb.collection(tabName).getFullList(400,));
            if (tabName === "invoice") {
                data.sort((a, b) => a.invoice.localeCompare(b.invoice));
            }
            for (var i in data){
                if (tabName === "assignments") {
                    val.set(data[i].id, [data[i].assignments_name, data[i].client_id , data[i].archived])
                } else if (tabName === "invoice") {
                    (data[i].aktiv && val.set(data[i].id, data[i].invoice))
                } else {
                val.set(data[i].id, data[i].name || data[i].client_name)
                }
            }
        }
        tablesToMap(invList,"invoice")
        tablesToMap(persList,"users")
        tablesToMap(clientList,"clients")
        tablesToMap(assignmentList,"assignments")

        getRecords("time", ('date >= "' + yearFrom + '" && person = "' + pb.authStore.model.id + '"'), "").then(json => {
            setHtimeId()
            setUnfiltredRecords(json)
            return json
        }).then(json =>{
            //const filtred = json.filter(post => post.person.includes(pb.authStore.model.id))
            setSerchRecords(json.filter(post => post.date.includes(filter)))
        })
        clientSync()
    }, [invList,persList,clientList,assignmentList])

    return (
        <div aria-disabled="!isValide" className="App w-full bg-plat">
            <div className="flex flex-col h-screen w-full">
                <div className="flex flex-col border-black h-20 border-b bg-base-100 ">
                    <div className="flex justify-between align-middle">
                        <div className="relative top-5">
                            <h1 className="text-3xl font-medium text-black w-40 px-5">Stunden</h1>
                        </div>
                        <div className="grid grid-flow-col my-4">
                            {multiEdit&& (<button className="btn btn-outline mx-5" onClick={()=>document.getElementById('multiEditModal').showModal()}>Multi Edit</button>)}
                            <SumHour records={serchRecords}></SumHour>
                            <DatePicker dateFormat="yyyy/MM/dd" id="DateEdit" className="input input-bordered input-md rounded-lg p-2.5 mx-1 text-center" selected={new Date(filter)} onChange={(e) => handleChangeDatePicker(new Date(e))}></DatePicker>
                            <SerchbarHour posts={unfiltredRecords} setSerchRecords={setSerchRecords} filter={filter}/>
                            <form onSubmit={handleSubmit}>
                                <input type="radio" name="radio-1" aria-label="Tag" value={dayForm} onChange={e => handleChange(e)} defaultChecked={true} className="btn btn-outline mx-1" />
                                <input type="radio" name="radio-1" aria-label="Monat" value={monthFrom} onChange={e => handleChange(e)} className="btn btn-outline" />
                            </form>
                            <label className="btn btn-outline btn-circle rounded-full mx-5 swap swap-rotate">
                                <input type="checkbox" onChange={() => setHourAdd(!hourAdd)}/>
                                <AiOutlinePlus className="swap-off text-2xl"></AiOutlinePlus>
                                <AiOutlineClose className="swap-on text-2xl"/>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center overflow-auto h-full">
                    {unfiltredRecords.length !== 0 ?
                        <Hourlist serchRecords={serchRecords}
                                  setSerchRecords={setSerchRecords}
                                  unfiltredRecords={unfiltredRecords}
                                  setUnfiltredRecords={setUnfiltredRecords}
                                  htime_id={htime_id}
                                  setHtime_id={setHtime_id}
                                  persList={persList}
                                  assignmentList={assignmentList}
                                  clientList={clientList}
                                  invList={invList}
                                  hourAdd={hourAdd}
                                  setHourAdd={setHourAdd}
                                  filter={filter}
                                  setMultiEdit={setMultiEdit}
                                  multiEdit={multiEdit}
                        ></Hourlist> :
                        <span className="loading loading-dots loading-lg text-primary">Loading</span>}
                </div>
            </div>
        </div>
    );
}

export default Hours;
