import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import PocketBase from "pocketbase";
import {NavLink} from "react-router-dom";
import { AiOutlineFieldTime, AiOutlineShoppingCart, AiOutlineLogout } from "react-icons/ai";
import { MdOutlineAssignment, MdQueryStats } from "react-icons/md";
import clientSync from "../hooks/syncer";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const Sidenav = () => {

    const [logouted, setLogouted] = useState(0)

    const logout = () =>{
        pb.authStore.clear()
        window.location.reload(false)
    }

    return (
        <div className="flex flex-col items-center w-72 h-screen overflow-hidden text-gray-300 bg-primary shadow-2xl">
            <div className="h-20 px-6 w-full border-b border-gray-300">
                <NavLink className="" to="/">
                    <h1 className="py-6 text-3xl">ma-<b className="text-maxrange">X</b>-time</h1>
                </NavLink>
            </div>
            <div className="w-full ">
                <div className="flex flex-col items-center w-full px-2">
                    <NavLink className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-maxrange"
                       to="/">
                        <svg className="w-f h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"/>
                        </svg>
                        <span className="ml-2 text-sm font-medium">Dasboard</span>
                    </NavLink>
                    <NavLink className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-maxrange"
                       to="hours">
                        <AiOutlineFieldTime className="w-6 h-6"/>
                        <span className="ml-2 text-sm font-medium">Stunden</span>
                    </NavLink>
                </div>
                <div className="flex flex-col items-center w-full mt-2 border-t border-gray-300 px-2">
                    <NavLink className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-maxrange"
                       to="clients">
                        <AiOutlineShoppingCart className="w-6 h-6"/>
                        <span className="ml-2 text-sm font-medium">Kunden</span>
                    </NavLink>
                    <NavLink className="flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-maxrange"
                       to="assignments">
                        <MdOutlineAssignment className="w-6 h-6"/>
                        <span className="ml-2 text-sm font-medium">Aufträge</span>
                    </NavLink>
                    <NavLink className="relative flex items-center w-full h-12 px-3 mt-2 rounded hover:bg-maxrange" to="stats">
                        <MdQueryStats className="w-6 h-6"/>
                        <span className="ml-2 text-sm font-medium">Gesamt Statitic</span>
                    </NavLink>
                </div>
            </div>
            <a className="flex items-center justify-center w-full h-16 mt-auto bg-primary hover:bg-maxrange border-t border-gray-300"
               href="#" onClick={logout}>
                <AiOutlineLogout className="w-6 h-6"></AiOutlineLogout>
                <span className="ml-2 text-sm font-medium">Logout</span>
            </a>
        </div>
    );
};

export default Sidenav;
