import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import {update} from "../../api/crud";
import {AiOutlineClose, AiOutlineSend} from "react-icons/ai";
import PocketBase from "pocketbase";
import assignments from "../../pages/Assignments";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const ClientEdit = ({editData,setEditdata,editVis,setEditVis}) => {

    const handleChange = (val,e) => {
        let obj = {}
            obj = {
                [val]: e.target.value
            };

        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    return (
        <div className="border border-gray-700 grid grid-cols-7 gap-5 h-16 mb-6 bg-white text-black rounded-2xl shadow-2xl items-center">
            <input name="discEdite" type="text" id="discEdit" value={editData.client_name} onChange={(e) => handleChange("client_name",e)} className="text-md font-bold border w-auto  rounded input-sm col-span-2 ml-5"/>
            <input name="discEdite" type="text" id="discEdit" value={editData.client_number} onChange={(e) => handleChange("client_number",e)} className="border rounded w-full input-sm text-center"/>
            <input name="discEdite" type="text" id="discEdit" value={editData.ustID} onChange={(e) => handleChange("ustID",e)} className="border rounded w-full input-sm text-center"/>
            <select className="bg-gray-50 border border-gray-300 block text-center text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 select-sm" value={editData.prio_score} onChange={(e) => handleChange("prio_score",e)}>
                <option value={75}>75</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
            </select>
            <div className="flex justify-center ">{editData.old_client_id}</div>
            <div className="flex justify-center">
                    <button onClick={() => update("clients", editData)} className="flex justify-center hover:text-maxrange text-3xl mx-3"><AiOutlineSend/></button>
                    <button onClick={() => setEditVis(!editVis) && setEditdata({})} className="flex justify-center hover:text-maxrange text-3xl mx-3"><AiOutlineClose/></button>
                </div>
        </div>
    )
};

export default ClientEdit;
