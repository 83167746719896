import React, {useEffect, useState} from "react";
import '../App.css';
import LoginForm from "../components/TimeForm";
import Sidenav from "../components/Sidenav";
import Hourlist from "../components/hours/hourlist";
import LoginScreen from "../components/loginScreen";
import PocketBase from "pocketbase";
import TimeForm from "../components/TimeForm";
import {Outlet} from "react-router-dom";
import clientSync from "../hooks/syncer";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const Layout = () => {

    useEffect(() => {
        clientSync()
    }, []);
    return (
        <>
            <div className="flex flex-row">
                <Sidenav/>
                <Outlet/>
            </div>
        </>
    );
}

export default Layout;
