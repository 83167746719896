import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {VictoryChart, VictoryBar, VictoryPie} from 'victory';
import CircularProgressBar from "./circularProgressbar";
import {getRecords} from "../../api/crud";
import {json} from "react-router-dom";
import PocketBase from "pocketbase";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');


const YearGoalPieChart = ({text,time}) => {

    const date = new Date()
    const [recordsMonth, setRecordsMonth] = useState([]);

    return (
        <div className="w-full h-full grid grid-flow-col place-content-center px-2">
            <h1 className="text-xl font-medium
            self-center">{text}</h1>
            <CircularProgressBar
                selectedValue={time.toFixed(2)}
                maxValue={2060}
                radius={75}
                strokeWidth={30}
                activeStrokeColor='#FF6633'
                withGradient={false}
                />
        </div>
    );
}

export default YearGoalPieChart;
