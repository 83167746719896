import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import {add} from "../../api/crud";
import {AiOutlineSend} from "react-icons/ai";
import PocketBase from "pocketbase";
import {toast} from "sonner";
import {tr} from "date-fns/locale";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');
const HourAdd = ({invList,clientList, htime_id,assignmentList, filter, setSearchRecords, setUnfiltredRecords}) => {

    const [newId, setNewId] = useState(htime_id);
    const [nstartDate, setNStartDate] = useState(new Date());
    const initialData = {
        date: new Date(),
        person: pb.authStore.model.id,
        old_time_id: newId + 1,
        invoice: "v1lklfnvgu8l069",
        paid: true,
    };
    const [editData, setEditdata] = useState(initialData);

    let filtredAssignments = Array.from(assignmentList).filter(post => post[1].includes(editData.client) && !post[1][2]);
    filtredAssignments = filtredAssignments.sort((a, b) => a[1][0] > b[1][0]);

    const handleSubmit = (e) => e.preventDefault();

    const handleAdd = async () => {
        const newRecord = await add("time", editData);
        setSearchRecords(prevRecords => [...prevRecords, newRecord].filter(post => post.date.includes(filter)));
        setUnfiltredRecords(prevRecords => [...prevRecords, newRecord]);

        // Reset editData and nstartDate to initial values
        setNewId(newId + 1)
        setEditdata(initialData);
        setNStartDate(new Date());
    };

    const handleChange = (val,e) => {
        let obj = {}
        if (val === "date"){
            setNStartDate(new Date(e))
            obj = {
                date: nstartDate
            };
        } else if (val === "time"){
            if (editData.time != null){
                if (e.target.value < 1){
                    obj = {
                        time: (parseInt(editData.time.toString().replace(/.\d+/, "")) + parseFloat(e.target.value))
                    };
                } else {
                    obj = {
                        time: (parseFloat(e.target.value) + parseFloat("0.".concat(((editData.time.toString()).split("."))[1])))
                    }
                }
            } else {
                obj = {
                    time: e.target.value
                }
            }
        } else {
            obj = {
                date: nstartDate,
                old_time_id: newId + 1,
                [val]: e.target.value
            };
        }
        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    return (
        <div
            className="border border-gray-700 grid grid-cols-11 gap-5 h-16 mb-6 bg-white text-black rounded-2xl shadow-2xl items-center">
            <DatePicker dateFormat="yyyy/MM/dd" id="DateEdit"
                        className="rounded-lg w-5/6 ml-5 p-2.5 text-center col-start-1" selected={nstartDate}
                        onChange={(e) => handleChange("date", e)}/>
            <select
                value={editData.client || ""}
                className="select select-bordered text-primary focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                id="CLietEdit" onChange={(e) => handleChange("client", e)}>
                <option key="kunde" hidden value="">Kunde</option>
                {Array.from(clientList).sort((a, b) => {
                    return a[1].toLowerCase() > b[1].toLowerCase()
                }).map(([value, key]) => (<option key={key} value={value}>{key}</option>))}
            </select>
            <select
                value={editData.assignment || ""}
                className="select select-bordered text-primary focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                id="AssignmentEdit" onChange={(e) => handleChange("assignment", e)}>
                <option key="auftrag" hidden value="">Auftrag</option>
                {Array.from(filtredAssignments).map(([value, key]) => (
                    <option key={key} value={value}>{key[0]}</option>))}
            </select>
            <textarea
                value={editData.discr || ""}
                name="discEdite"
                id="discEdit"
                onChange={(e) => handleChange("discr", e)}
                className="text-md font-bold border w-full col-span-3 rounded"/>
            <p className="flex justify-center">{pb.authStore.model.name}</p>
            <select
                value={editData.invoice || ""}
                className="select select-bordered text-primary focus:ring-blue-500 focus:border-blue-500 block w-5/6 p-2.5 "
                id="InvoiceEdit" onChange={(e) => handleChange("invoice", e)}>
                <option key="abrechnung" hidden value="">Abrechnung</option>
                {Array.from(invList).map(([value, key]) => (<option key={key} value={value}>{key}</option>))}
            </select>
            <div id="TimeEdit" className="flex justify-center col-span-2 w-5/6">
                <select
                    value={editData.time ? Math.floor(editData.time) : ""}
                    className="select select-bordered rounded-r-none text-primary focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    onChange={(e) => handleChange("time", e)}>
                    {Array.from({length: 17}).map((it, index) => <option
                        key={index} value={index}>{index + " Stunden"}</option>)}
                </select>
                <select
                    value={editData.time ? (editData.time % 1).toFixed(2) : ""}
                    className="select select-bordered rounded-l-none text-primary focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    onChange={(e) => handleChange("time", e)}>
                    <option value={0.00}>0 Min</option>
                    <option value={0.16}>10 Min</option>
                    <option value={0.25}>15 Min</option>
                    <option value={0.33}>20 Min</option>
                    <option value={0.50}>30 Min</option>
                    <option value={0.67}>40 Min</option>
                    <option value={0.75}>45 Min</option>
                </select>
            </div>
            <button type="button" onClick={() => handleAdd()}
                    className="flex justify-center hover:text-maxrange text-3xl mx-3"><AiOutlineSend/></button>
        </div>
    )
};

export default HourAdd;
