import React, {useEffect, useState} from "react";
import PocketBase from "pocketbase";
import {AiOutlineHistory} from "react-icons/ai";
import {getRecords} from "../../api/crud";
import clientSync from "../../hooks/syncer";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

function StatsGenerallReport({records,}) {
    const faktorial = { percent: 0, total: 0, sales_volume: 0 }
    const budgetAssignments = []
    const sumableBudgets = []

    for (let i in records) {
        if (records[i].paid) {
            faktorial.total += records[i].time
        }
        if (records[i].expand.assignment.budget === 0 && records[i].paid) {
            faktorial.sales_volume += records[i].time * records[i].expand.assignment.hourRate
        } else {
            if (budgetAssignments.indexOf(records[i].assignment) === -1 && records[i].paid) {
                budgetAssignments.push(records[i].assignment)
                sumableBudgets.push(records[i].expand.assignment.budget)
            }
        }
    }

    sumableBudgets.forEach(x => {
        faktorial.sales_volume += x
    })
    faktorial.percent = Math.round((faktorial.total / records.length) * 100)

    return (
        <div className="grid grid-cols-1 grid-rows-2 gap-5 ml-5 mt-5">
            <div className="stats stats-vertical lg:stats-horizontal shadow">
                <div className="stat">
                    <div className="stat-title">Faktorierbar</div>
                    <div className="stat-value">{faktorial.percent} %</div>
                    <div className="stat-desc">Jan 1st - Dezember 31st</div>
                </div>

                <div className="stat">
                    <div className="stat-title">Geschetzter Umsatz</div>
                    <div className="stat-value text-left tooltip" data-tip="Geschätzt nach Stunden und Budgettierung">{faktorial.sales_volume.toFixed(2)}</div>
                    <div className="stat-desc ">↗︎ 400 (22%)</div>
                </div>

                <div className="stat">
                    <div className="stat-title">New Registers</div>
                    <div className="stat-value">1,200</div>
                    <div className="stat-desc">↘︎ 90 (14%)</div>
                </div>
            </div>
            <div className="stats stats-vertical lg:stats-horizontal shadow">
                <div className="stat">
                    <div className="stat-title">Downloads</div>
                    <div className="stat-value">31K</div>
                    <div className="stat-desc">Jan 1st - Feb 1st</div>
                </div>

                <div className="stat">
                    <div className="stat-title">New Users</div>
                    <div className="stat-value">4,200</div>
                    <div className="stat-desc">↗︎ 400 (22%)</div>
                </div>

                <div className="stat">
                    <div className="stat-title">New Registers</div>
                    <div className="stat-value">1,200</div>
                    <div className="stat-desc">↘︎ 90 (14%)</div>
                </div>
            </div>
        </div>
    );
}

export default StatsGenerallReport;
