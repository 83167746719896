import React, {useState} from 'react';
import PocketBase from "pocketbase";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const LoginScreen = () => {

    const [logFaild, setLogFaild] = useState(false)

    const [form, setForm] = useState({
        email: '',
        password: '',
    });


    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,

        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const userdata = await pb.collection('users').authWithPassword(form.email, form.password)
            .then(() => {
                window.location.reload(false)
            })
            .catch(err => {
                setLogFaild(true)
                console.log(err)
        });
    };

    return (
        <div className="bg-gray-700">
            {logFaild && (<div className="alert alert-error absolute">
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>Login fehlgeschlagen :(<br/>Vesuchen sie andere zugangsdaten zu verwenden</span>
                <button className="btn btn-sm" onClick={() => setLogFaild(false)}>ok</button>
            </div>)}
            <div className="flex justify-center flex-col w-screen h-screen bg-gray-700">
                <h1 className="flex justify-center text-5xl text-white">Wilkommen bei ma-<b className="text-maxrange">X</b>-time</h1>
                <div className="flex justify-center mt-10">
                    <form className="flex flex-col justify-center" id="LoginForm" onSubmit={handleSubmit}>
                        <div>
                            <input className="m-2 w-80 h-10 rounded" placeholder="Email" id="email" type="email" value={form.email} onChange={handleChange}/>
                        </div>
                        <div>
                            <input className="m-2 w-80 h-10 rounded" id="password" placeholder="Password" type="password" value={form.password} onChange={handleChange}/>
                        </div>
                        <div className="flex justify-center">
                            <button className="hover:bg-maxrange mt-5 text-white w-72 h-10 rounded bg-gray-800" type="submit">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LoginScreen;
