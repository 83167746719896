import React, {useState} from 'react';
import DatePicker from "react-datepicker";
import {add, getRecords} from "../../api/crud";
import {AiOutlineSend} from "react-icons/ai";
import PocketBase from "pocketbase";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');
const AssignmentAdd = ({records,invList,clientList,assignmentList,hassignment_id, filter, setSearchRecords, setUnfiltredRecords}) => {
    const date = new Date()
    const [newId, setNewId] = useState(hassignment_id);
    const [prioVal, setPrioVal] = useState(75);
    const [calc, setCalc] = useState();
    const [stdHR, setStdHR] = useState(130);
    const [stdBud, setStdBud] = useState(0);

    console.log(hassignment_id)

    const getHAssignID = async () => {
        return await pb.collection('assignments').getFirstListItem("", {sort: "-old_assign_id"});
    }

    const initialData = {
        a_date: date,
        old_assign_id: hassignment_id + 1,
        archived: false,
        completed: false,
        billable: false,
        recurring: false,
        budget: stdBud,
        hourRate: stdHR,
        pm: pb.authStore.model.id,
    }
    const [editData, setEditdata] = useState(initialData);

    const handleSubmit = (e) => e.preventDefault();
    const handleChange = (val,e) => {
        console.log(hassignment_id)
        let obj = {
            old_assign_id: hassignment_id + 1,
            [val]: e.target.value
        };
        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    const handleAdd = async () => {
        const addedRecords = await add("assignments", editData);
        const newRecord = await pb.collection('assignment_stats').getOne(addedRecords.id);
        setSearchRecords(prevRecords => [...prevRecords, newRecord]);
        setUnfiltredRecords(prevRecords => [...prevRecords, newRecord]);

        // Reset editData and nstartDate to initial values
        setNewId(newId + 1)
        setEditdata(initialData);
    };

    return(
        <>
            <dialog id="modalAssAdd-Generel" className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-white">
                    <h3 className="font-bold text-lg">Grundlegende Infos</h3>
                    <div className="divider"/>
                    <div className="form-control grid grid-cols-1 gap-2">
                        <input type="text" placeholder="Auftrags Name" className="input input-bordered w-full max-w-xl col-span-1 bg-white" onChange={e => handleChange("assignments_name", e)}/>
                        <select className="border border-gray-300 text-gray-900 bg-white rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" id="CLietEdit" onChange={(e) => handleChange("client_id",e)}><option hidden value="">Kunde</option>{Array.from(clientList).map(([value, key]) => (<option key={key} value={value}>{key}</option>))}</select>
                    </div>
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn" onClick={()=>document.getElementById('modalAssAdd-Prio').showModal()}>Weiter</button>
                        </form>
                    </div>
                </div>
            </dialog>
            <dialog id="modalAssAdd-Prio" className="modal modal-bottom sm:modal-middle">
                <div className="modal-box bg-white">
                    <h3 className="font-bold text-lg">Berechnung</h3>
                    <div className="divider"/>
                    <div className="flex justify-center grid grid-cols-1 gap-5">
                        <div className="join flex justify-center">
                            <input className="join-item btn bg-white" type="radio" name="options" aria-label="Budget" onChange={() => setCalc("budget")}/>
                            <input className="join-item btn bg-white" type="radio" name="options" aria-label="BNS" onChange={() => setCalc("bns")}/>
                        </div>
                        <input type="text" placeholder="Zeit Limit" className="flex justify-center input input-bordered w-full max-w-xs bg-white" onChange={(e) => handleChange("time_limit",e)}/>
                        {(calc === "budget") && (<input type="text" placeholder="Budget" className="flex justify-center input input-bordered w-full max-w-xs bg-white" onChange={(e) => setStdBud(e.target.value)}/>)}
                        {(calc === "bns") && (<input type="text" placeholder="Stunden Rate" value={stdHR} className="flex justify-center input input-bordered w-full max-w-xs bg-white" onChange={(e) => setStdHR(e.target.value)}/>)}
                    </div>
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn bg-white" onClick={()=>handleAdd()}>Anlegen</button>
                        </form>
                    </div>
                </div>
            </dialog>
            <dialog id="modalAdd-ApproxSales" className="modal modal-bottom sm:modal-middle">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Notizen zum Kunden</h3>
                    <div className="divider"/>
                    <textarea className="py-4 textarea textarea-bordered w-full" placeholder='Beispiel: "Kunde hat ein Rabat" oder "Bitte .... beruecksichtigen"...'></textarea>
                    <div className="modal-action">
                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn">Anlegen</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </>
    )
};

export default AssignmentAdd;

