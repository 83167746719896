import React from 'react';
import {AiOutlineSearch} from 'react-icons/ai';

const SerchbarClient = ({ posts, setSerchRecords, }) => {
    const handleSubmit = (e) => e.preventDefault()
    const handleSerchChange = (e) => {
        if (!e.target.value) return setSerchRecords(posts)

        const resultArray = posts.filter(post => (post.client_name.toLowerCase()).includes(e.target.value.toLowerCase()))
        setSerchRecords(resultArray)
    }

    return (
        <form onSubmit={handleSubmit}>
            <input className="input w-full max-w-xs input-bordered" placeholder="Search" type="text" id="serch" onChange={handleSerchChange}/>
        </form>
    )
};

export default SerchbarClient;
