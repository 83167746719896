import React, {useEffect, useState} from "react";
import StatYearReport from "../components/charts/statYearReport";
import {getRecords} from "../api/crud";
import clientSync from "../hooks/syncer";
import PocketBase from "pocketbase";
import StatsGenerallReport from "../components/charts/statsGenerallReport";

const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

function Statistics() {
    const date = new Date()
    const dayForm = `${date.getFullYear()}-${date.getMonth()+1}-${((date.getDate().toString()).length != 1) ? "":"0"}${date.getDate()}`;
    const monthFrom = `${date.getFullYear()}`;

    // States that are needed for getting Record
    const [unfiltredRecords, setUnfiltredRecords] = useState([])
    const [persList, serPersList] = useState([])


    useEffect( () => {
        getRecords("time", ('date > "' + monthFrom + '" && date < "' + (monthFrom + 1) + '"'), "stats").then(json => {
            setUnfiltredRecords(json)
            console.log(json)
            return json
        });
        getRecords("users").then( persons =>{
            serPersList(persons)
        });
        clientSync()
    }, [])

    return (
        <div aria-disabled="!isValide" className="App w-full bg-plat overflow-auto">
            <div className="flex flex-col h-screen w-full">
                <div className="h-20 border-black border-b bg-white flex">
                    <h1 className="text-3xl font-medium text-black w-50 px-5 self-center">Statistik {date.getFullYear().toString()}</h1>
                </div>
                <div className="grid grid-cols-2 grid-rows-2 gap-10 w-full h-[100vh]">
                    <div className="grid grid-rows-1 grid-cols-1">
                        <StatsGenerallReport records={unfiltredRecords} className="h-[50vh]"/>
                        <div/>
                    </div>
                    <div className="flex justify-center border overflow-auto">
                        <h4 className="text-lg font-medium text-black self-center">Comming soon</h4>
                        <div/>
                    </div>
                    <div className="flex justify-center col-span-2 overflow-auto shadow-2xl rounded pb-5">
                    {unfiltredRecords.length !== 0 ?
                            <StatYearReport records={unfiltredRecords} persons={persList}/> :
                            <span className="loading loading-dots loading-lg text-primary">Loading</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Statistics;
