import PocketBase from "pocketbase";
import axios, {AxiosError} from "axios";
import {toast} from "sonner";
import {el} from "date-fns/locale";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');


export const getRecords = async (col,filter,stats) => {
    if (col === "time" ) {
        if (filter !== "" && stats !== "stats"){
            var data = await pb.collection(col).getFullList(200, {expand: "client,assignment,person,invoice", filter: 'date >= "2022-12-29" && ' + filter , sort:"-old_time_id"})
        } else if (stats === "stats"){
            var data = await pb.collection(col).getFullList(200, {expand: "client,assignment",filter: ''+filter , sort:"-old_time_id"});
        } else {
            var data = await pb.collection(col).getFullList(200, {expand: "client,assignment,person,invoice", filter: 'date >= "2022-12-29"' , sort:"-old_time_id"});
        }
    } else if (col === "assignments"){
        /*if (filter !== ""){
            var data = await pb.collection(col).getFullList(200, {expand: "client_id,pm", filter: 'a_date >= "2015-12-29" &&' + filter , sort:"-old_assign_id"});
        }*/
        if (filter !== "" && stats !== "client"){
            var data = await pb.collection(col).getFullList(200, {expand: "client_id,pm", filter: 'a_date >= "2015-12-29" &&' + filter , sort:"-old_assign_id"});
        } else if (stats === "client"){
            var data = await pb.collection("assignment_stats").getFullList(200, {expand: "client_id,pm", filter: 'a_date >= "2000-01-01" &&' + filter , sort:"-old_assign_id"});
        } else {
            var data = await pb.collection("assignment_stats").getFullList( 500,{ filter: 'a_date >= "2015-12-29"' , sort:"-client_id"});
        }
        //console.log(filter)
    } else if (col === "clients"){
        var data = await pb.collection(col).getFullList(200, {sort:"-old_client_id"});
    } else {
        var data = await pb.collection(col).getFullList(200 );
    }
    return data
}

export const getOne = async (col, id, exp) => {
    var data = await pb.collection(col).getOne(id, {expand: exp});
    return data
}

export const update = async (table,editData) => {
    try {
        await pb.collection(table).update(editData.id, editData).then((result) => { return result });
        toast.success("Erfolgreich gespeichert")
    } catch (error) {
        toast.error("Eintragen fehlgeschlagen: " + error);
    }
    if(table === "time") {
        const assignment = await pb.collection("assignments").getOne(editData.assignment)
        const person = await pb.collection("users").getOne(editData.person)
        const invoice = await pb.collection("invoice").getOne(editData.invoice)
        const client = await pb.collection("clients").getOne(editData.client)
        axios.post('https://dbsyncer.ma-x.de/updateTime', {editData,assignment,person,invoice,client})
            .then(function (response) {
                //window.location.reload()
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    } else if(table === "clients") {
        axios.post('https://dbsyncer.ma-x.de/updateClient', editData)
            .then(function (response) {
                window.location.reload()
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }else if(table === "assignments") {
        const client = await pb.collection("clients").getOne(editData.client_id)
        axios.post('https://dbsyncer.ma-x.de/updateAssignment', {editData, client})
            .then(function (response) {
                //window.location.reload()
                //toast.success('Erfolgreich gespeichert')
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    } else {
        window.location.reload()
    }
};

export const add = async (table,editData, setEditdata) => {
         const newRecord = await pb.collection(table).create(editData, {expand: "client,assignment,person,invoice"})
             .then((result) => {
                 toast.success("Erfolgreich gespeichert")
                 return result
             })
             .catch(function (error){
                toast.error("Eintragen fehlgeschlagen: " + error);
            });
        if (table === "time"){
            OldDBAdd(editData)
        } else if (table === "clients"){
            axios.post('https://dbsyncer.ma-x.de/createClient', {editData})
                .then(function (response) {
                    window.location.reload()
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        }else if (table === "assignments"){
            const client = await pb.collection("clients").getOne(editData.client_id)
            const pm = pb.authStore.model.old_pers_id
            axios.post('https://dbsyncer.ma-x.de/createAssignment', {editData,client,pm})
                .then(function (response) {
                    console.log(response)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
        } else {
            window.location.reload()
        }
        return newRecord
}

export async function OldDBAdd(editData) {
    const assignment = await pb.collection("assignments").getOne(editData.assignment)
    const person = await pb.collection("users").getOne(editData.person)
    const invoice = await pb.collection("invoice").getOne(editData.invoice)
    const client = await pb.collection("clients").getOne(editData.client)
    axios.post('https://dbsyncer.ma-x.de/createTime', {editData, assignment, person, invoice, client})
        .then(function (response) {
            //window.location.reload()
            console.log(response)
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
}

function removePB(id){
    fetch('https://dbsyncer.ma-x.de/delOlDDBTime', {
        method: 'POST',
        body: JSON.stringify({
            id:id
        }),
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    }).then(function(response){
        return response.json()
    }).catch(error => console.error('Error:', error));
}

export const del = async (table,id,old_id) =>{
    await pb.collection(table).delete(id);
    if (table === "time"){
        axios.post('https://dbsyncer.ma-x.de/delTime',{old_time_id: old_id})
            .then(function (){
                //window.location.reload()
                toast.success('Eintrag gelöscht!')
            })
            .catch(function (error){
                console.log(error)
            })
    } else if (table === "clients"){
        axios.post('https://dbsyncer.ma-x.de/delClient',{old_client_id: old_id})
            .then(function (){
                window.location.reload()
            })
            .catch(function (error){
                console.log(error)
            })
    }else if (table === "assignments"){
        axios.post('https://dbsyncer.ma-x.de/delAssignment',{old_assign_id: old_id})
            .then(function (){
                toast.success('Eintrag gelöscht!')
            })
            .catch(function (error){
                toast.success('Fehler!: ' + error.message)
                console.log(error)
            })
    } else {
        window.location.reload()
    }
};
