import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import {update} from "../../api/crud";
import {AiOutlineSend} from "react-icons/ai";
import PocketBase from "pocketbase";
import assignments from "../../pages/Assignments";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const HourEdit = ({editData,setEditdata,editVis,setEditVis,persList,invList,clientList,assignmentList, setUnfiltredRecords, setSearchRecords}) => {

    const date = new Date(editData.date)
    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    let filtredAssignments = Array.from(assignmentList).filter(post => post[1].includes(editData.client) && !post[1][2])
    filtredAssignments = filtredAssignments.sort((a, b) => {
        return a[1][0] > b[1][0]
    });
    const dateForm = `${date.getFullYear()}-0${date.getMonth()+1}-0${date.getDate()}`
    const [startDate, setStartDate] = useState(new Date());
    const handleChange = (val,e) => {
        let obj = {}
        if (val === "date"){
            obj = {
                date: `${e.getFullYear()}-${(((e.getMonth()+1).toString()).length !== 1) ? "":"0"}${e.getMonth()+1}-${((e.getDate().toString()).length !== 1) ? "":"0"}${e.getDate()}`
            };
        } else if (val === "time"){
            if (editData.time != null){
                if (e.target.value < 1){
                    obj = {
                        time: (parseInt(editData.time.toString().replace(/.\d+/, "")) + parseFloat(e.target.value))
                    };
                } else {
                    obj = {
                        time: (parseFloat(e.target.value) + parseFloat("0.".concat(((editData.time.toString()).split("."))[1])))
                    }
                }
            } else {
                obj = {
                    time: e.target.value
                }
            }
        } else {
            obj = {
                person: pb.authStore.model.id,
                [val]: e.target.value
            };
        }
        setEditdata(editData => ({...editData,...obj}))
        console.log(editData)
    };

    const handleUpdate = async (data) => {
        update("time", data).then(async () => {
            const newRecord = await pb.collection('time').getOne(data.id, {expand: 'person,client,assignment,invoice'});
            setSearchRecords(prevRecords => [...prevRecords].filter(post => post.id !== data.id));
            setUnfiltredRecords(prevRecords => [...prevRecords].filter(post => post.id !== data.id));
            setSearchRecords(prevRecords => [...prevRecords, newRecord]);
            setUnfiltredRecords(prevRecords => [...prevRecords, newRecord]);
            setEditVis(false)
        })
    }

    useEffect(() => {
        let timeSplitt = (editData.time).toString().split(".")
        setHour(timeSplitt[0])
        if (timeSplitt[1] === "66666667"){
            timeSplitt[1] = 67
        }
        if (timeSplitt[0] != 0 && timeSplitt[1]){
            setMin("0." + timeSplitt[1].toString())
        } else {
            setMin(editData.time)
        }

        setStartDate(new Date(editData.date.toString().split(" ")[0]))
    },[editData])

    return (
        <div className="border border-gray-700 grid grid-cols-10 gap-5 h-16 mb-6 bg-white text-black rounded-2xl shadow-2xl items-center">
            <DatePicker dateFormat="yyyy/MM/dd" id="DateEdit" className="rounded-lg border w-5/6 ml-5 p-2.5 text-center col-start-1" selected={startDate} onChange={(e) => handleChange("date", e)}/>
            <select className="select select-bordered text-primary focus:ring-blue-500 focus:border-blue-500 block p-2.5" value={editData.client} id="CLietEdit" onChange={(e) => handleChange("client",e)}>{Array.from(clientList).sort((a,b) => {return a[1].toLowerCase() > b[1].toLowerCase()}).map(([value, key])=> (<option value={value}>{key}</option>))}</select>
            <select className="select select-bordered text-primary focus:ring-blue-500 focus:border-blue-500 block p-2.5" value={editData.assignment} id="CLietEdit" onChange={(e) => handleChange("assignment",e)}>{Array.from(filtredAssignments).map(([value, key])=> (<option value={value}>{key[0]}</option>))}</select>
            <textarea name="discEdite" id="discEdit" value={editData.discr} onChange={(e) => handleChange("discr",e)} className="text-md font-bold border w-auto mx-5 rounded col-span-2"/>
            <select className="select select-bordered text-primary focus:ring-blue-500 focus:border-blue-500 block  p-2.5" value={editData.person} id="PersEdit">{Array.from(persList).map(([value, key])=> (<option className="text-center" value={value}>{key}</option>))}</select>
            <select className="select select-bordered text-primary focus:ring-blue-500 focus:border-blue-500 block p-2.5 " value={editData.invoice} id="InvoiceEdit" onChange={(e) => handleChange("invoice",e)}>{Array.from(invList).map(([value, key])=> (<option value={value}>{key}</option>))}</select>
            <div id="TimeEdit" className="flex justify-center col-span-2 w-5/6">
                <select className="select select-bordered rounded-r-none text-primary focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={hour} onChange={(e) => handleChange("time",e)}>{Array.from({length:17}).map((it,index) => <option value={index}>{index}</option>)}</select>
                <select className="select select-bordered rounded-l-none text-primary-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={min} onChange={(e) => handleChange("time",e)}>
                    <option value={0.00}>0 Min</option>
                    <option value={0.16}>10 Min</option>
                    <option value={0.25}>15 Min</option>
                    <option value={0.33}>20 Min</option>
                    <option value={0.50}>30 Min</option>
                    <option value={0.67}>40 Min</option>
                    <option value={0.75}>45 Min</option>
                </select>
            </div>
            <button onClick={() => handleUpdate(editData) && setEditVis(!editVis)} className="flex justify-center hover:text-maxrange text-3xl mx-3"><AiOutlineSend/></button>
        </div>
    )
};

export default HourEdit;
