import React from 'react';
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import {del} from "../../api/crud";
import {Link} from "react-router-dom";

const Client = ({records, edit}) => {
    return (
        <>
        {records.map((data, index) => (
                <div key={index} className="grid grid-flow-col grid-cols-7 h-16 mb-6 bg-base-100 text-primary border border-primary rounded-2xl items-center shadow-2xl">
                    <div className="pl-5 col-start-1"><input type="checkbox" className="checkbox checkbox-MD checked:checkbox-accent form-checkbox rowCheckbox focus:outline-none focus:shadow-outline" /*handleClick={handleClick} isChecked={isCheck.includes(index)}*//></div>
                    <div className="text-md font-bold col col-start-2 col-span-2"><Link to={"/client/" + data.id }>{data.client_name}</Link></div>
                    <div className=" ">{data.client_number}</div>
                    <div className=" ">{data.ustID}</div>
                    <div className=" ">{data.prio_score}</div>
                    <div className=" ">{data.old_client_id}</div>
                    <div className="flex justify-center mx-5">
                        <button onClick={() => del("clients",data.id,data.old_client_id)} className="hover:text-maxrange text-3xl mx-3"><AiOutlineDelete/></button>
                        <button onClick={() => edit(data.id)} className="hover:text-maxrange text-3xl mx-3"><AiOutlineEdit/></button>
                    </div>
                </div>
            ))}
        </>
    );
};

export default Client;
