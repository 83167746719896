import React, {Fragment ,useState} from 'react';
import DatePicker from 'react-datepicker';
import PocketBase from 'pocketbase';
import Modal from 'react-modal';
import {AiOutlineDelete, AiOutlineEdit, AiOutlineSend} from "react-icons/ai";
const pb = new PocketBase('https://backend.ma-xtime.ma-x.de/');

const TimeForm = () => {
    const [startDate, setStartDate] = useState(null);
    const [form, setForm] = useState({
        discr: '',
        time: '',
        invoice: '',
    });

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,

        });
    };

    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        await pb.collection('time').create({
            date: startDate,
            discr: form.discr,
            time: form.time,
            person: pb.authStore.model.id,
            invoice: form.invoice
        });

        alert(form.discr + ' ' + form.time + ' ' + form.invoice + ' ' + startDate + pb.authStore.model.id);
    };

    return (
        <div className="flex flex-col h-16 border-black h-20 border-b bg-white ">
            <div className="flex justify-between align-middle">
                <div className="relative top-5">
                    <h1 className="text-3xl font-medium text-black w-40 px-5">Stündchen</h1>
                </div>
                <div className="relative top-5 right-5">
                    <button
                        type="button"

                        className=" text-black border border-black rounded-full bg-white px-10 py-2 text-sm font-medium  hover:bg-maxrange focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">Hinzufügen
                    </button>
                </div>
            </div>

        </div>
    );
};

export default TimeForm;
