import React from 'react';

function AssignmentExpand({data, updCheckboxes, checkEscalation}) {
    return (
        <div className="grid grid-cols-2">
            <div className="col-start-1 col-span-1 border-r border-primary mt-5">
                <div className="grid grid-cols-3 justify-items-center">
                    <div className="flex flex-col items-center">
                        <div
                            className={`radial-progress ${
                                isFinite(data.total_paid / data.time_limit) && !isNaN(data.total_paid / data.time_limit)
                                    ? checkEscalation(data, 'tl')
                                    : ''
                            }`}
                            style={{
                                '--value':
                                    !isFinite(data.total_paid / data.time_limit) || isNaN(data.total_paid / data.time_limit)
                                        ? 100
                                        : ((data.total_paid / data.time_limit) * 100).toFixed(0)
                            }}
                            role="progressbar"
                        >
                            {!isFinite(data.total_paid / data.time_limit) || isNaN(data.total_paid / data.time_limit)
                                ? 'NTL'
                                : `${((data.total_paid / data.time_limit) * 100).toFixed(0)}%`}
                        </div>
                        <label className="text-xs">Zeit Limit: {data.time_limit}</label>
                    </div>
                    <div className="flex flex-col items-center">
                        <div
                            className={`radial-progress ${
                                data.budget !== 0
                                    ? checkEscalation(data, 'bd')
                                    : ''
                            }`}
                            style={{
                                '--value':
                                    data.budget !== 0
                                        ? (((data.hourRate * data.total_paid) / data.budget) * 100).toFixed(0)
                                        : 100
                            }}
                            role="progressbar"
                        >
                            {data.budget !== 0
                                ? `${((data.hourRate * data.total_paid) / data.budget * 100).toFixed(0)}%`
                                : 'NB'}
                        </div>
                        <label className="text-xs">Budget: {data.budget}</label>
                    </div>
                    <div className="flex flex-col self-center">
                        <label className="text-s" data-tip="Stundenlohn (Euro)">
                            Stundenrate: {data.hourRate} €
                            <div className="tooltip"
                                 data-tip="Stundenlohn * Fakt. Stunden">Verbraucht: {(data.hourRate * data.total_paid).toFixed(2)} €</div>
                        </label>
                    </div>
                </div>
                <div className="grid grid-cols-4 justify-items-center mt-4">
                    <div className="flex flex-col items-center">
                        <label className="text-xs mb-1">Abrechenbar</label>
                        <input
                            className="toggle toggle-accent"
                            type="checkbox"
                            checked={data.billable}
                            value={data.billable}
                            onChange={(e) => updCheckboxes(data.id, 'billable', !data.billable)}
                        />
                    </div>
                    <div className="flex flex-col items-center">
                        <label className="text-xs mb-1">Fertig</label>
                        <input
                            className="toggle toggle-accent"
                            type="checkbox"
                            checked={data.completed}
                            value={data.completed}
                            onChange={(e) => updCheckboxes(data.id, 'completedCheck', !data.completed)}
                        />
                    </div>
                    <div className="flex flex-col items-center">
                        <label className="text-xs mb-1">Wiederkehrend</label>
                        <input
                            className="toggle toggle-accent"
                            type="checkbox"
                            checked={data.recurring}
                            value={data.recurring}
                            onChange={(e) => updCheckboxes(data.id, 'recurring', !data.recurring)}
                        />
                    </div>
                    <div className="flex flex-col items-center">
                        <label className="text-xs mb-1">Archiviert</label>
                        <input
                            className="toggle toggle-accent"
                            type="checkbox"
                            checked={data.archived}
                            value={data.archived}
                            onChange={(e) => updCheckboxes(data.id, 'archived', !data.archived)}
                        />
                    </div>
                </div>
            </div>
            <div className="col-start-2 col-span-1 my-5 overflow-auto">
                <p className="mx-3 pb-2 border-b border-primary font-bold">Note:</p>
                <div className="mx-5 my-3" dangerouslySetInnerHTML={{
                    __html: new DOMParser().parseFromString(data.note, 'text/html').body
                        .textContent,
                }}>
                </div>
            </div>
        </div>
    );
}

export default AssignmentExpand;
